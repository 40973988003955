// @font-face {
// 	font-family: 'Futura Bk BT';
// 	src: url('../fonts/Futura-Book.eot');
// 	src: url('../fonts/Futura-Book.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/Futura-Book.woff') format('woff'),
// 		url('../fonts/Futura-Book.ttf') format('truetype'),
// 		url('../fonts/Futura-Book.svg#FuturaBT-Book') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Futura Bk BT';
// 	src: url('../fonts/Futura-Bold.eot');
// 	src: url('../fonts/Futura-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/Futura-Bold.woff') format('woff'),
// 		url('../fonts/Futura-Bold.ttf') format('truetype'),
// 		url('../fonts/Futura-Bold.svg#FuturaPT-Bold') format('svg');
// 	font-weight: bold;
// 	font-style: normal;
// }

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans.eot');
	src: url('../fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans.woff') format('woff'),
		url('../fonts/OpenSans.ttf') format('truetype'),
		url('../fonts/OpenSans.svg#OpenSans') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Bold.eot');
	src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Bold.woff') format('woff'),
		url('../fonts/OpenSans-Bold.ttf') format('truetype'),
		url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
