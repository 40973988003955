// search styles

.search-body {
	min-height: 600px;
	margin-bottom: 45px !important;

	p {
		font-size: 1.5em;
		margin: 45px 0 0 20px;
	}
}

.gsc-adBlock {
	display: none;
}