/* standard-header styles*/
.m-navy {
    position: fixed;
    z-index: 5;
    top: 0;

    width: 100%;

    background-color: $white;
    box-shadow: 0 0 15px $grey;

    .m-logo {
        overflow: hidden;

        vertical-align: middle;

        a {
            float: left;
        }

        a:first-child {
            img {
                width: 60px;
                padding: 0 5px;
            }
        }
        a:nth-child(2) {
            img {
                width: 72px;
                margin: 10px 0 7px;
                padding: 13px 10px;

                border-left: 1px solid $lightgrey;
            }
        }

        a:last-child {
            img {
                width: 80px;
                margin: 20px 0 0;
            }
        }
    }
}

.m-search {
    position: absolute;
    top: 53%;
    right: 65px;

    display: inline-block;

    -webkit-transform: translate(0, -53%);
            transform: translate(0, -53%);

    a {
        text-decoration: none;

        &:hover {
            border: none;
        }
    }

    .m-icon-search {
        font-size: 1.5em;

        color: $pri-green;
    }
}

.m-search-container {
    position: fixed;
    top: 72px;
    left: 0;

    width: 100%;
    padding: 20px;

    -webkit-transition: -webkit-transform .25s ease-in-out;
            transition: -webkit-transform .25s ease-in-out;
            transition:         transform .25s ease-in-out;
            transition:         transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
    transform: scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top;

    color: #fff;
    background: $pri-green;

    &.open {
        transform: scaleY(1);
    }

    #m-search-site {
        width: 100%;
        padding-right: 25px;

        transition: border .15s ease-in-out;

        color: #fff;
        border: 0;
        border-bottom: 1px solid #fff;
        outline: 0;
        background: none;
    }

    .m-btn-search {
        font-size: 1.5em;

        position: absolute;
        top: auto;
        right: 20px;
        bottom: 20px;

        padding: 0;

        color: #fff;
        border: 0;
        background: none;
    }
}

.m-nav-icon {
    position: absolute;
    top: 50%;
    right: 23px;

    display: none;
    display: inline-block;

    cursor: pointer;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);

    .bar1,
    .bar2,
    .bar3 {
        width: 25px;
        height: 5px;
        margin: 4px 0;

        border-radius: 3px;
        background-color: $pri-green;
    }
}

.m-nav-close {
    position: absolute;
    top: 50%;
    right: 23px;

    cursor: pointer;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);

    .bar4,
    .bar5 {
        width: 0;
        height: 5px;

        border-radius: 3px;
        background-color: $pri-green;
    }

    .bar4 {
        -webkit-transform: rotate(45deg) translate(1.8px, 1.8px);
                transform: rotate(45deg) translate(1.8px, 1.8px);
    }
    .bar5 {
        -webkit-transform: rotate(-45deg) translate(1.8px, -1.8px);
                transform: rotate(-45deg) translate(1.8px, -1.8px);
    }
}

.m-nav-list {
    font-family: $primaryfont;
    font-size: 1em;

    position: relative;
    z-index: 0;

    p.padded {
        padding: 15px 36px;

        cursor: pointer;

        border-bottom: 1px solid $grey;
    }

    li.padded {
        padding: 16px 36px;

        cursor: pointer;

        border-bottom: 1px solid $grey;
    }

    p {
        margin: 0;

        white-space: nowrap;

        color: $mobile-icon;
    }

    .sub-head {
        background-color: $pri-green;
        p {
            color: $white;
        }
    }

    ul {
        list-style-type: none;

        li {
            position: relative;

            display: block;

            color: $mobile-icon;

            a {
                white-space: nowrap;
                text-decoration: none;

                color: $mobile-icon;

                &:hover {
                    border: none;
                }
            }
        }
    }

    .icon-main {
        padding: 0 23px 0 0;
    }

    .icon-chevron-right {
        float: right;
    }

    .icon-chevron-left {
        position: absolute;
        left: 31px;
    }

    .slideLeft {
        position: fixed;
        top: 72px;
        right: 0;

        li:first-child {
            text-align: center;
        }

        .next {
            text-transform: none;
        }
    }

    .icon {
        font-size: 1.1em;
    }

    .m-home.active {
        padding: 15px 30px;

        border-left: 6px solid $pri-green;

        a {
            color: $pri-green;
        }
    }

    .active {
        padding: 0;

        border-left: 6px solid $pri-green;
    }
    .active > p.next {
        padding: 15px 36px 15px 28px;

        color: $pri-green;
    }
}

.next1,
.next2,
.back1,
.back2 {
    cursor: pointer;
}

.slideLeft {
    overflow-y: hidden;

    width: 0;
    height: 100vh;

    -webkit-transition: .5s;
            transition: .5s;

    background-color: $white;
}

.level1 {
    z-index: 5;
}

.level2 {
    z-index: 7;
}

.slideDown {
    position: fixed;
    z-index: 3;
    top: 71px;

    overflow-y: hidden;

    width: 100%;
    height: 0;

    -webkit-transition: .5s;
            transition: .5s;

    background-color: $white;
    // text-transform: uppercase;
    .nav__title {
        text-transform: uppercase;
    }
}


.homeHeader {
    position: absolute;
    top: -2px;
    width: 100%;
    height: 200px;

    background: $headerGradTop; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient($headerGradTop, $headerGradMid1, $headerGradMid2, $headerGradBot); /* For Safari 5.1 to 6.0 */ /* For Opera 11.1 to 12.0 */ /* For Firefox 3.6 to 15 */
    background:         linear-gradient($headerGradTop, $headerGradMid1, $headerGradMid2, $headerGradBot);
    background-size: 100% 100%;
}

.standardHeader {
    position: absolute;

    width: 100%;
    height: 75px;

    background-color: $navbarBlack;
}

.header {
    position: relative;
    z-index: 5000;

    @include tablet {
        .header__top-standard {
            display: none;

            padding: 5px 0;
        }
    }
    @include mobile {
        .desktop {
            padding: 5px 0;

            background-color: $white;
        }

        .header__top-standard {
            display: none;
        }

        .header-bottom-standard {
            display: none;
        }
        /* .mobile-btn {*/
        /*  font-size: 0.8em;*/
        /* }*/
    }
    @include tablet {
        .header-bottom-standard {
            display: none;
        }
    }

    .header__top-standard {
        padding: 0;

        .header-wrapper {
            display: table;
            display: relative;
            clear: both;

            width: 100%;
            padding: 15px 0 18px;

            content: '';

            @include mobile {
                .ubin-logo a img {
                    height: 60px;
                }

                .national-park-logo a img {
                    height: 60px;
                }

                .header__gov {
                    display: none;
                }
            }
            @include tablet {
                .header__gov {
                    display: none;
                }
            }

            /* .mobile-btn {*/
            /*  position: absolute;*/
            /*  right: 0;*/
            /*  top: 50%;*/
            /*  transform: translate(0, -50%);*/
            /*  float: right;*/
            /*  color: $pri-green;*/
            /*  text-decoration: none;*/
            /*  font-weight: bold;*/
            /*  padding: 9px 10px;*/
            /*  background-color: rgba(230, 230, 230, 1);*/
            /*  border-radius: 7px;*/
            /*  margin: auto 15px;*/
            /* }*/

            /* .mobile-btn:hover {*/
            /*  transition: all 0.3s;*/
            /*  box-shadow: 0 0 8px $pri-green;*/
            /* }*/

            /* @include desktop {*/
            /*  .mobile-btn {*/
            /*    display: none;*/
            /*  }*/
            /* }*/
            /* @include tablet {*/
            /*  .mobile-btn {*/
            /*    margin: 0 20px 0 0;*/
            /*  }*/
            /* }*/

            .logos {
                position: absolute;
                top: 0;

                display: block;
                float: left;

                margin: 0;
                padding: 0;

                .logo-list {
                    overflow: auto;

                    li {
                        display: inline-block;
                        // float: left;

                        a {
                            img {
                                padding: 0 15px 0 0;

                                vertical-align: middle;

                                border: none;
                            }
                        }
                    }

                    li:first-child {
                        a {
                            img {
                                width: 105px;
                            }
                        }
                    }

                    li:nth-child(2) {
                        a {
                            img {
                                width: 118px;
                                margin: 20px 0 0 0;
                                padding: 22px 15px 17px;

                                border-left: 2px solid $lightgrey;
                            }
                        }
                    }

                    li:last-child {
                        a {
                            img {
                                width: 180px;
                                margin: 15px 0 0 0;
                            }
                        }
                    }
                }
            }

            .header__gov {
                display: inline-block;
                float: right;

                margin: 0;

                text-align: right;

                .gov-links {
                    overflow: auto;

                    margin: -5px 0 0 0;
                    padding: 0 0 10px;

                    li {
                        display: inline;
                        float: right;

                        margin: 0;
                        padding: 0;

                        border-right: 2px solid lightgrey;

                        a {
                            font-size: 90%;

                            padding: 0 15px;

                            text-decoration: none;

                            color: black;
                        }

                        a:hover {
                            color: $text-hover;
                        }
                    }

                    li:first-child {
                        border: none;
                        a {
                            padding: 0 0 0 15px;
                        }
                    }
                }

                .header__search-bar {
                    background-color: #eee;

                    ::-webkit-input-placeholder {
                        font-family: $primaryfont;
                        font-style: italic;
                    }

                    ::-moz-placeholder {
                        font-family: $primaryfont;
                        font-style: italic;
                    }

                    :-ms-input-placeholder {
                        font-family: $primaryfont;
                        font-style: italic;
                    }

                    :-moz-placeholder {
                        font-family: $primaryfont;
                        font-style: italic;
                    }

                    select {
                        font-family: inherit;
                    }

                    input {
                        font-size: .875em;

                        position: relative;
                        left: 3px;

                        width: 180px;
                        padding: 4px 5px 5px;

                        border: none;
                    }

                    select#searchDropdown {
                        font-size: 12px;

                        width: 190px;

                        border: none;
                        background: transparent;

                        option {
                            background: transparent;
                        }
                    }

                    select#searchDropdown:focus {
                        outline: none;
                    }

                    button.btn-search {
                        width: 31px;
                        height: 31px;

                        cursor: pointer;
                        text-decoration: none;

                        color: $white;
                        border: none;
                        background-color: $pri-green;

                        .icon-search {
                            font-size: 14px;

                            margin: auto;
                        }
                    }
                }
            }
        }
    }

    .header__top-standard:after {
        display: table;
        clear: both;

        content: '';
    }

    ul {
        margin: 0;
        padding: 0;

        list-style-type: none;
    }

    .header-bottom-standard {
        position: relative;

        nav {
            .navDropdownList {
                display: none;
            }

            .s-active > a {
                padding: 10px 0 8px;

                border-bottom: 2px solid $white;
            }

            ul {
                li {
                    position: relative;

                    float: left;

                    padding: 0;

                    p {
                        font-size: .9em;
                        line-height: 1em;

                        display: block;

                        margin: 20px 33.5px;
                        padding: 10px 0;

                        cursor: pointer;
                        text-align: center;
                        text-decoration: none;

                        color: $white;
                    }

                    @media (min-width: 961px) and (max-width: 1020px) {
                        p {
                            margin: 20px 28px;
                            padding: 10px 0;

                            -webkit-transition: all.0.5s;
                                    transition: all.0.5s;
                        }
                    }

                    ul {
                        position: absolute;

                        -webkit-transition: all .5s;
                                transition: all .5s;

                        background-color: $white;
                        box-shadow: 0 0 20px rgba(95, 95, 95, 1);

                        li {
                            float: none;

                            padding: 0;

                            p,
                            a {
                                font-size: .9em;

                                display: block;

                                width: 225px;
                                margin: 0;
                                padding: 12px 27px;

                                text-align: left;
                                text-decoration: none;

                                color: $white;
                                color: $mobile-icon;
                                background-color: $white;
                                /* border-top: 1px solid $text-off-white;*/
                            }

                            p:hover,
                            a:hover {
                                -webkit-transition: all .5s;
                                        transition: all .5s;

                                color: $white;
                                background-color: $navbarBlack;
                            }
                        }

                        li:first-child {
                            a {
                                padding: 20px 27px 12px;
                            }
                        }

                        li:last-child {
                            p,
                            a {
                                padding: 12px 27px 20px;
                            }

                            li {
                                a {
                                    padding: 12px 27px;
                                }
                            }

                            li:first-child {
                                a {
                                    padding: 20px 27px 12px;
                                }
                            }

                            li:last-child {
                                a {
                                    padding: 12px 27px 20px;
                                }
                            }
                        }
                    }
                    .outer {
                        left: 50%;

                        -webkit-transform: translate(-50%, 0);
                                transform: translate(-50%, 0);
                    }
                    .outer::after {
                        position: absolute;
                        bottom: 100%;
                        left: 50%;

                        margin-left: -8px;

                        content: '';

                        border-width: 8px;
                        border-style: solid;
                        border-color: $clear-white $clear-white $white $clear-white;
                    }

                    .inner {
                        left: 100%;
                    }
                }
                .homeBtn {
                    a {
                        font-size: .9em;
                        line-height: 1em;

                        display: block;

                        margin: 0;
                        padding: 30px 33.5px 30px 0;

                        text-align: center;
                        text-decoration: none;

                        color: $white;
                    }
                }
                @media (min-width: 961px) and (max-width: 1020px) {
                    .homeBtn {
                        a {
                            padding: 30px 28px 30px 0;

                            -webkit-transition: all .5s;
                                    transition: all .5s;
                        }
                    }
                }

                .navDropdown:first-child {
                    margin-left: -33.5px;
                }

                @media (min-width: 961px) and (max-width: 1020px) {
                    .navDropdown:first-child {
                        margin-left: -33.5px;
                        .outer {
                            left: 23px;

                            -webkit-transform: none;
                                    transform: none;
                        }
                        .outer::after {
                            left: 30px;

                            content: '';
                        }
                    }
                }
                @media (min-width: 1021px) and (max-width: 1365px) {
                    .navDropdown:first-child {
                        margin-left: -33.5px;

                        .outer {
                            left: 23px;

                            -webkit-transform: none;
                                    transform: none;
                        }
                        .outer::after {
                            left: 36.5px;

                            content: '';
                        }
                    }
                }
            }

            .nav__list:after {
                display: table;
                clear: both;

                content: '';
            }

            .nav__list {
                li {
                    &.homeBtn {
                        text-transform: uppercase;
                    }

                    .nav__title {
                        text-transform: uppercase;
                    }

                    .navDropdownList {
                        .nav__title {
                            text-transform: none;
                        }
                    }
                }
            }

            .ubinBtn {
                position: relative;

                float: right;

                .ubinLink {
                    font-size: .95em;
                    line-height: 1em;

                    margin: 20px 0;
                    padding: 10px 17px;

                    color: $black;
                    border-radius: 7px;
                    background-color: $white;
                }

                .ubinDrop {
                    position: absolute;
                    top: calc(100% - 20px);

                    border-radius: 7px;
                    background-color: $white;
                    box-shadow: 0 1px 20px rgba(95, 95, 95, 1);

                    a {
                        width: 173.344px;
                        padding: 10px 27px;
                    }

                    li:first-child a {
                        border-radius: 7px 7px 0 0;
                    }
                    li:last-child a {
                        border-radius: 0 0 7px 7px;
                    }
                }
            }

            .ubinBtn:hover {
                .ubinLink {
                    -webkit-transition: ease-in .2s;
                            transition: ease-in .2s;

                    background-color: $white-70;
                }
            }
        }

        nav li li {
            position: relative;
        }

        nav li li ul {
            position: absolute;
            top: 0;
            left: 200px;

            display: none;
        }
    }

    .header-bottom-standard:after {
        display: table;
        clear: both;

        content: '';
    }
}

@include desktop {
    .m-navy,
    .m-nav-list {
        display: none;
    }

    .homepageHeader {
        .header-bottom-standard {
            nav ul {
                li p,
                .homeBtn a {
                    font-weight: bold;

                    color: #000;
                }
            }
        }
    }
}
