// sitecore-form styles
/* =====================
Sitecore Form (Old)
===================== */
.scfForm {
	// font-size: calc-em(16px);
	// color: $bodyColor;
	// background: $standard-bg;
	padding: 0 0 0 0;

	// Form Title
	.scfTitleBorder {
		display: inline-block;
		// border-bottom: 6px solid $ios-blue;
		// font-size: calc-em(30px, 16px);
		padding: 0;
	}

	// Form Intro
	.scfIntroBorder {

		p {

		}
	}

	// Validation
	.scfValidationSummary, .scfValidatorRequired, .scfValidator {
		// color: $ios-red;
	}

	// Required star
	.scfRequired {
		// color: $ios-red;
		// position: absolute;
		display: none;
	}

	// Submit Summary at Top
	.scfSubmitSummary {
		// color: $ios-green;
	}

	/*============== Layout ==============*/
	// Main container
	.scfSectionBorder {

	}

	// Sub main container
	.scfSectionContent {

	}

	// Wrapper for fields
	.scfDropListBorder, .scfEmailBorder, .scfMultipleLineTextBorder, .scfSingleLineTextBorder, .scfPasswordBorder, .scfNumberBorder, .scfDatePickerBorder, .scfDateBorder, .scfRadioButtonListBorder, .scfListBoxBorder, .scfCheckBoxListBorder, .scfFileUploadBorder, .scfDateSelectorBorder, .scfCreditCardBorder, .scfConfirmPasswordBorder, .scfCaptchaBorder, .scfTelephoneBorder, .scfSmsTelephoneBorder {
		margin: 0;
		padding: 0 0 28px 0;
	}

	// Wrapper for the input of fields
	.scfEmailGeneralPanel, .scfMultipleLineGeneralPanel, .scfSingleLineGeneralPanel, .scfPasswordGeneralPanel, .scfNumberGeneralPanel, .scfDatePickerGeneralPanel, .scfDateGeneralPanel, .scfRadioButtonListGeneralPanel, .scfCheckBoxListGeneralPanel, .scfFileUploadGeneralPanel, .scfDateSelectorGeneralPanel, .scfCreditCardGeneralPanel, .scfConfirmPasswordGeneralPanel, .scfCaptchaGeneralPanel, .scfTelephoneGeneralPanel, .scfSmsTelephoneGeneralPanel {
		width: 100%;
		padding: 5px 0 0 0;
		@include desktop {
			max-width: 485px;
		}
		input {
			padding: 0 0 0 18px;
			// width: 100%;
		}
		textarea {
			padding: 10px 18px 0 18px;
			// width: 100%;
		}
	}

	.scfDropListBorder, .scfRadioButtonListBorder, .scfListBoxBorder, .scfCheckBoxListBorder {
		padding: 0 0 28px 0;
	}

	.scfCheckBoxListGeneralPanel, .scfRadioButtonListGeneralPanel {
		padding: 0;
	}


	// Checkbox
	.scfCheckboxBorder {
		padding: 0 0 28px 0;
	}

	// Footer
	.scfFooterBorder {
		p {

		}
	}

	// Submit button
	.scfSubmitButtonBorder {
		padding: 20px 0 0 0;
		input {
			background-color: $clear-white;
			border: 2px solid $pri-green;
			font-size: 0.875em;
			text-transform: uppercase;
			margin-top: 10px;
		}
	}

	.scfSubmitButtonBorder {
		width: 174.53px;
		margin: 0 auto 25px;

		@include desktop {
			margin: 0;
		}
	}

	.scfSubmitButtonBorder:hover {
		input {
			background-color: $pri-green;
			color: $white;
		}
	}

	.scfSubmitButton {
		// @extend .btn;
		color: $pri-green;
		font-weight: bold;
		padding: 10px 60px;
	}

	.scfSubmitButton:hover {
		color: $white;
	}


	/*============= Element ==============*/
	input, select, textarea {
		font-family: $primaryfont;
	}

	// Labels
	.scfDropListLabel, .scfEmailLabel, .scfMultipleLineTextLabel, .scfSingleLineTextLabel, .scfPasswordLabel, .scfNumberLabel, .scfDatePickerLabel, .scfDateLabel, .scfRadioButtonListLabel, .scfCheckBoxListLabel, .scfListBoxLabel, .scfFileUploadLabel, .scfDateSelectorLabel, .scfCreditCardLabel, .scfConfirmPasswordLabel, .scfCaptchaLabel, .scfTelephoneLabel, .scfSmsTelephoneLabel {
		width: 100%;
		font-family: $primaryfont;
	}

	.scfCaptchaLabel {
		display: none;
	}

	.scfCaptchaGeneralPanel table {
		margin: 0;
	}

	// Single Line text box
	.scfEmailTextBox, .scfSingleLineTextBox, .scfPasswordTextBox, .scfNumberTextBox, .scfDatePickerTextBox, .scfDateTextBox, .scfRadioButtonList, .scfListBox, .scfCheckBoxList, .scfCreditCardTextBox, .scfCreditCardType, .scfConfirmPasswordTextBox, .scfTelephoneTextBox, .scfSmsTelephoneTextBox {
		// background: transparent;
		// border: 0;
		// border-bottom: 1px solid $ios-border;
		height: 50px;
		width: 100%;
		max-width: none;
		border: 1px solid $lightgrey;
		label {
			padding: 0 0 0 12px;
		}
	}

	.scfCheckBoxList, .scfRadioButtonList {
		border: none;
	}

	// Multiple Line Box
	.scfMultipleLineTextBox {
		height: 360px;
		max-width: none;
		border: 1px solid $lightgrey;
		width: 100%;
	}

	.scfFileUploadGeneralPanel input {
		padding: 0;
	}

	// Useful Info
	.scfDropListUsefulInfo, .scfEmailUsefulInfo, .scfMultipleLineTextUsefulInfo, .scfSingleLineTextUsefulInfo, .scfPasswordUsefulInfo, .scfNumberUsefulInfo, .scfDatePickerUsefulInfo, .scfDateUsefulInfo, .scfRadioButtonListUsefulInfo, .scfListBoxUsefulInfo, .scfCheckBoxListUsefulInfo, .scfFileUploadUsefulInfo, .scfDateSelectorUsefulInfo, .scfCreditCardTextUsefulInfo, .scfConfirmPasswordUsefulInfo, .scfCaptchaUsefulInfo, .scfTelephoneUsefulInfo, .scfSmsTelephoneUsefulInfo {
		display: none;
	}

	// Date Selector Label
	.scfDateSelectorShortLabelDay, .scfDateSelectorShortLabelMonth, .scfDateSelectorShortLabelYear {
		width: calc(33.3333333% - 10px);
		font-family: $primaryfont;
		font-size: 0.85em;
		padding: 0 0 5px 0;
	}

	.scfDateSelectorShortLabelDay, .scfDateSelectorShortLabelMonth {
		// margin: 0 0 0 10px;
		margin: 0 0 0 15px;
	}

	// Date selector field
	.scfDateSelectorDay, .scfDateSelectorMonth, .scfDateSelectorYear {
		float: left;
		padding: 0 0 0 18px;
		height: 38px;
		width: calc(33.3333333% - 10px);
		font-family: $primaryfont;
		border: 1px solid $lightgrey;
	}

	.scfDateSelectorDay {
		margin: 0;
	}

	.scfDateSelectorMonth, .scfDateSelectorYear {
		margin: 0 15px 0 0;
	}

	// checkbox
	.scfCheckbox {
		input {

		}

		label {

		}
	}

	// File Upload
	.scfFileUpload {

	}

	// .scfCaptchaGeneralPanel table * {
	// 	margin: 0;
	// 	width: 100%;
	// 	max-width: none;

	// 	img {
	// 		min-width: none;
	// 	}
	// }

	// Dropdown and listbox
	.scfListBox, .scfDropList {
		height: 50px;
		padding: 0 0 0 18px;
		border: 1px solid $lightgrey;
	}

	// .scfDropList {
	// 	width: 100% !important;
	// 	> .select2 .select2-container .select2-container--default .select2-container--focus {
	// 		width: 100% !important;
	// 	}
	// }

	.scfDropListGeneralPanel, .scfListBoxGeneralPanel {
		width: 100%;
		@include desktop {
			max-width: 485px;
		}
		padding: 5px 0 0 0;
		select {
			width: 100%;
		}
	}

	// Radio buttons list
	.scfRadioButtonList {
		input {
			display: inline;
		}

		label {

		}
	}

	// Checkbox list
	.scfCheckBoxList {
		input {

		}

		label {

		}
	}

	.scfCaptchaBorder {
		padding: 0 0 8px 0;
	}

	.scfCaptchStrongTextPanel {
		width: 100%;
		padding: 5px 0 0 0;
		.scfCaptchaTextBox  {
			height: 50px;
			width: 100%;
			border: 1px solid $lightgrey;
		}
	}

	.scfCaptcha img {
		border: 1px solid $lightgrey;
	}
}

.scfForm * {
	font-family: $primaryfont;
}

@include desktop {
	.scfForm {
		padding: 40px 0 0 0;
	}
}
