// newsroom styles
.newsroom-main {
	padding: 20px 20px 15px;

	@include desktop {
		padding: 54px 0 38px;
	}
}
.standard-banner {
	position: relative;
	width: 100%;
	height: 150px;
	overflow: hidden;
	background-size: cover;
	background-position: center;

	@include desktop {
		background-size: cover;
		background-position: initial;
		margin-bottom: -22px;
	}

	h1 {
		color: $white;
		text-align: center;
		@include desktop {
			text-align: left;
		}
		margin: 80px 0 0 0;
		padding: 0;
		font-size: 1.85em;
		position: relative;
		z-index: 3;
	}
}

.standard-banner:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 2;
}

.newsroom-text {
	position: relative;
	z-index: 3;
}

.select2-container .select2-selection--single {
	height: 40px;
	border-radius: 0px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	margin: 6px 0;
	padding-left: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	margin: 7px 9px 7px 0;
}

.select2-container--default .select2-results > .select2-results__options {
	max-height: none;
	font-size: 0.875em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $pri-green;
}


.filter-comp {
	display: inline-block;
	width: 49%;
	font-size: 0.875em;

	@include desktop {
		width: 135px;
		margin: 0 16px 0 0;
	}

	label {
		display: block;
		padding: 0 0 7px 0;
	}
	select {
		font-family: $primaryfont;
		width: 100%;
		height: 40px;
		padding: 0 0 0 14px;
	}
}
button.filter-comp {
	// @include mobile {
	// 	width: 100%;
	// 	margin: 20px 0 0 0;
	// }
	// // @include tablet {

	// // }
	width: 100%;
	margin: 20px 0 0 0;
	@include desktop {
		width: auto;
		padding: 0 60px;
	}
	font-family: $primaryfont;
	color: $pri-green;
	font-weight: bold;
	font-size: 0.875em;;
	background-color: $clear-white;
	border: 2px solid $pri-green;
	height: 40.5px;
}

button.filter-comp:hover {
	background-color: $pri-green;
	color: $white;
}


.newscards-wrapper {
	padding: 35px 0 0 0;
}

.newscard {
	position: relative;
	background-color: $white;
	margin: 0 0 25px 0;
	p {
		padding-right: 32px;
		padding-left: 32px;
	}

	.newscard-date {
		padding-top: 17px;
		padding-bottom: 3px;
		font-size: 0.8em;
	}

	.newscard-description {
		padding-top: 7px;
		padding-bottom: 20px;
		font-size: 0.875em;
	}

	a {
		width: 100%;
		height: 100%;
		display: inline-block;
		position : absolute;
		top: 0;
		left: 0;
	}
}

.newscard:hover {
	border-left: 6px solid $pri-green;
	box-shadow: 0 0 15px rgba(200, 200, 200, 0.8);
	p {
		padding-left: 26px;
	}
	.newscard-description {
		color: $pri-green;
	}
}

// .pagination {
// 	position: relative;
// 	margin: auto;
// 	padding: 0 0 15px 0;
// 	@include desktop {
// 		// margin: 0;
// 		// padding: 28px 0 0 168px;
// 		padding: 28px 0 0;
// 	}
// 	a {
// 		display: inline-block;
// 		text-decoration: none;
// 		color: black;
// 		width: 30px;
// 		height: 30px;
// 		float: left;
// 		padding: 5px;
// 		text-align: center;
// 		margin: 0 0 0 7px;
// 	}
// 	a:first-child {
// 		margin: 0;
// 	}

// 	a:nth-child(3), a:nth-child(4), a:nth-child(5) {
// 		font-size: 0.875em;
// 		padding-top: 7px;
// 	}

// 	a:nth-child(6), a:nth-child(7), a:nth-child(8) {
// 		font-size: 0.875em;
// 		padding-top: 7px;
// 		display: none;
// 		@include desktop {
// 			display: inline-block;
// 		}
// 	}

// 	> .active {
// 		background-color: $pri-green;
// 		color: $white;
// 	}

// 	.backArrow, .forwardArrow {
// 		background-color: $pri-green;
// 		color: $white;
// 		.icon {
// 			font-size: 1.2em;
// 		}
// 	}

// 	.backArrow {
// 		transform: rotate(180deg);
// 	}
// }

// .pagination {
// 	content: '';
// 	clear: both;
// 	display: table;
// }
