// activity-calendar styles


.activity-container {
	background-size: cover;
	background-position: center;
	position: relative;

	.calendar-section-container {
		position: relative;
		z-index: 2;

		::-webkit-scrollbar {
			display: none;
		}

		.calendar-title {
			width: 28%;
			max-width: 335px;

			h2 {
				color: $black;
				padding: 70px 0 25px;
				margin: 0;
			}
		}

		@include desktop {
			.m-view-more-events {
				display: none;
			}
		}

		@include mobile {
			.view-more-events {
				display: none;
			}
			.calendar-title {
				width: 100%;
				max-width: none;
				text-align: center;
				h2 {
					color: $pri-green;
					padding: 30px 15px 20px;
				}
			}
		}

		@include tablet {
			.view-more-events {
				display: none;
			}

			.calendar-title {
				width: 100%;
				max-width: none;
				text-align: center;
				h2 {
					color: $pri-green;
					padding: 30px 15px 20px;
				}
			}
		}
	}

}


.calendar-section-content {
	// overflow: hidden;
	position: relative;

	.calendar-column-box {
		display: inline;
		overflow: hidden;
		-ms-overflow-style: none;
	}

	.calendar-column {
		max-height: inherit;
		float: left;
		overflow: auto;
		margin: 0;

		.calendar-event-card {
			background-color: $white;
			margin: 0 15px 15px;
			position: relative;

			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.event-header {

				.event-title {
					width: 65%;
					border-bottom: 2px solid $pri-green;
					display: inline-block;
					vertical-align: middle;

					h4 {
						padding: 17px 30px 8px;
						margin: 0;
						text-transform: capitalize;
						font-weight: normal;
					}

				}

				.event-date {
					display: inline-block;
					color: $pri-green;

					p {
						padding: 0 0 0 25px;
						margin: 0;
						font-size: 0.85em;
					}
				}
			}

			.event-info {

				p {
					padding: 17px 60px 20px 30px;
					line-height: 16px;
					margin: 0;
					font-size: 0.85em;
				}
			}
		}

		.calendar-event-card:last-child {
			margin: 0 15px;
		}

		.calendar-event-card:hover {
			transition: all 0.5s;
			box-shadow: 0 0 20px rgba(160, 160, 160, 1);

		}
	}

	.blur-box {
		position: absolute;
		bottom: 0;
		width: 35vw;
		max-width: 335px;
		height: 50px;
		background: linear-gradient(to bottom, $clear-white, $white-box);
		margin: 0;
	}

	@include mobile {
		.blur-box {
			display: none;
		}

		.calendar-column {
			width: 100%;
			padding: 0 0 20px 0;

			.calendar-event-card {

				.event-header {

					.event-title {
						width: 80%;
						border-bottom: 2px solid $pri-green;
						display: block;

						h4 {
							padding: 25px 15px 0;
							margin: 10px 0;
							text-transform: capitalize;
							font-weight: normal;
						}

					}

					.event-date {
						display: block;

						p {
							padding: 0 0 0 15px;
							margin: 8px 0;
						}
					}
				}

				.event-info {

					p {
						padding: 0 15px 20px;
						line-height: 20px;
						margin: 0;
					}
				}
			}

			.calendar-event-card:nth-child(4),
			.calendar-event-card:nth-child(5) {
				display: none;
			}
		}
	}

	@include tablet {
		.blur-box {
			display: none;
		}

		.calendar-column {
			width: 100%;
			padding: 0 0 20px 0;

			.calendar-event-card {

				.event-header {

					.event-title {
						width: 80%;
						display: block;

						h4 {
							padding: 25px 35px 0;
							margin: 10px 0;
							text-transform: capitalize;
							font-weight: normal;
						}

					}

					.event-date {
						display: block;

						p {
							padding: 0 0 0 35px;
							margin: 8px 0;
						}
					}
				}

				.event-info {

					p {
						padding: 0 15px 20px 35px;
						line-height: 20px;
						margin: 0;
					}
				}
			}

			.calendar-event-card:nth-child(4),
			.calendar-event-card:nth-child(5) {
				display: none;
			}
		}

		.calendar-event-card:nth-child(4),
		.calendar-event-card:nth-child(5) {
			display: none;
		}
	}

	@include desktop {
		.calendar-column {
			height: 36.666667vw;
			max-height: 440px;
			margin: 0 -150px 0 0;
			padding: 0 150px 0 0;

			.calendar-event-card {
				max-width: 335px;
				margin: 0 0 17.5px;
				min-height: 135px;
			}

			.calendar-event-card:last-child {
				margin: 0;
			}
		}
	}

	@media (min-width: 961px) and (max-width: 1200px) {
		.calendar-event-card {
			font-size: 90%;
		}
	}

	.guided-walks-column {
		position: relative;
		margin: 0;

		.guided-walks-list {
			padding: 0;
			margin: 0 15px 0;
		}
		.info-card-outer-box {
			background-color: $white;
			width: 100%;
			height: auto;

			.walk-info-card {
				background-color: $white;
				width: 80%;
				height: auto;
				margin: 0;

				h3 {
					text-transform: capitalize;
					padding: 25px 25px 10px 20px;
					margin: 0;
					width: 100%;
					border-bottom: 2px solid $pri-green;
				}

				p {
					line-height: 20px;
					padding: 0 20px 0;
				}

				@include mobile {
					p {
						height: 100px;
					}
				}

				@include tablet {
					p {
						height: 40px;
					}
				}

				a {
					text-transform: uppercase;
					text-decoration: none;
					display: block;
					color: $pri-green;
					font-weight: bold;
					padding: 0 20px 25px;
				}
			}
		}

	}

	@include desktop {
		.guided-walks-column {
			width: 63.5%;
			max-height: 440px;
			float: right;
			margin: 0;


			.guided-walks-list {
				margin: 0;

				.guided-walks-item {
					position: relative;
					background-size: cover;
					background-position: center;

					.walk-bg-img {
						width: 100%;
						visibility: hidden;
					}
				}
			}

			.walk-info-card {

				h3 {
					text-transform: capitalize;
					padding: 35px 25px 10px;
					margin: 0;
					width: 80%;
					border-bottom: 2px solid $pri-green;
				}

				p {
					line-height: 20px;
					padding: 0 25px 0;
					height: 60px;
				}

				a {
					text-transform: uppercase;
					text-decoration: none;
					display: block;
					color: $pri-green;
					font-weight: bold;
					padding: 0 25px 25px;
				}
			}
		}
		.calendar-section-content {
			max-height: 550px;
		}
		.guided-walks-nav {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;

			ul {
				margin: 0;
				padding: 0;
				overflow: auto;
				background-color: $white;

				li {
					list-style-type: none;
					float: left;
					width: 33.333333%;
					display: block;
					text-transform: capitalize;
					margin: 0;
					padding: 10px 20px;
					text-decoration: none;
					color: $black;
					border-top: 4px solid $clear-white;
					border-right: 1px solid $text-off-white;
					background-color: $white;
					text-align: center;
					padding: 10px 0;
					cursor: pointer;
				}

				li.walk-active {
					color: $pri-green;
					font-weight: bold;
					border-bottom: 3px solid $pri-green;
				}

				li.walks-nav:hover {
					font-weight: bold;
					color: $pri-green;
				}

				li:last-child {

					a {
						border-right: none;
					}
				}
			}
		}

		.guided-walks-list {
			margin: 0 0 0 0;
			padding: 0;
			height: 36.666667vw;
			max-height: 550px;

			.slick-list,
			.slick-track,
			li {
				height: 100%;
			}

			.info-card-outer-box {
				background-color: $white;
				position: absolute;
				top: 0;
				right: 0;
				width: 242px;
				height: 214px;

				.walk-info-card {
					background-color: $white;
					width: 220px;
					height: auto;
					margin: 0 0 0 23px;

					h3 {
						text-transform: capitalize;
						padding: 36px 0 12px 0;
						margin: 0;
						border-bottom: 2px solid $pri-green;
						font-weight: normal;
					}

					p {
						line-height: 20px;
						padding: 0 15px 0 0;
					}

					a {
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}

	@include tablet {
		.guided-walks-column {
			padding: 0;
		}
		.guided-walks-nav {
			display: none;
		}
		.guided-walks-item {
			background-image: none;
		}
		.walk-bg-img {
			width: 100%;
			visibility: visible;
		}

		.walk-info-card {
			min-height: 165px;
		}
	}

	@include mobile {
		.guided-walks-column {
			padding: 0;
		}
		.guided-walks-nav {
			display: none;
		}
		.guided-walks-item {
			background-image: none;
		}
		.walk-bg-img {
			width: 100%;
			visibility: visible;
		}

		.walk-info-card {
			min-height: 215px;
			h3 {
				height: 79px;
			}
		}
	}
}

.view-more-events {
	width: 30vw;
	max-width: 335px;
	text-align: right;
	padding: 25px 0 72px;
	a {
		color: $pri-green;
		font-weight: bold;
		text-decoration: none;
		padding: 0;

		.icon {
			display: inline;
			vertical-align: middle;
			margin-left: -8px;
			padding: 20px 0 0 0;
			opacity: 0;
			font-weight: bold;
		}
	}

	a:hover {
		border-bottom: 2px solid $pri-green;

		// .icon {
		// 	transition: ease-in 0.5s;
		// 	margin-left: 5px;
		// 	opacity: 1;
		// }
	}
}

.m-view-more-events {
	width: 100%;
	text-align: center;
	padding: 25px 0 35px;
	a {
		color: $pri-green;
		font-weight: bold;
		text-decoration: none;
		padding: 0;
	}

	a:hover {
		border-bottom: 2px solid $pri-green;
	}
}

.activity-container:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $white-70;
}

@include mobile {
	.activity-container:after {
		background-color: $lightgrey;
	}
	.info-card-title {
		font-weight: normal;
		padding: 0;
	}
}

@include tablet {
	.activity-container:after {
		background-color: $lightgrey;
	}
}

@include desktop {
	.calendar-section-content {
		overflow: hidden;
	}
}

.slick-dots {
	position: static;
	padding: 0;

	li {
		margin: 0;
		text-indent: 999px;
		overflow: hidden;
		border: 2px solid $icon-brown;
		border-radius: 7px;
		padding: 0;
		margin: 20px 3px 25px;
		width: 13px;
		height: 13px;
		display: inline-block;

	}

	.slick-active {
		background-color: $icon-brown;
	}
}