// social-gallery styles

.thumbnail-container {
	clear: both;
	content: '';
	display: table;
	width: 100%;

	.thumbnail {
		width: 20%;
		height: 20vw;
		background-size: cover;
		background-position: center;
		float: left;
		margin: 0;
		padding: 0;
		position: relative;
		@media (min-width: 1600px) {
			width: calc(100% / 7);
			height: calc(100vw / 7);
		}

		.thumbnail-caption {
			position: absolute;
			bottom: 0;
			width: 100%;
			@include background-gradient($clear-white, $black, vertical);

			img {
				display: inline-block;
				vertical-align: middle;
				border-radius: 50%;
				border: 2px solid $white;
				max-width: 50px;
				width: 20%;
				min-width: 25px;
			}

			p {
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				padding: 0 0 0 10px;
				color: $white;
				font-size: 0.875em;
			}
		}
	}

	.static-thumbnail {
		background-color: $pri-green;
		width: 20%;
		height: 20vw;
		background-size: cover;
		background-position: center;
		float: left;
		margin: 0;
		padding: 0;
		position: relative;
		@media (min-width: 1600px) {
			width: calc(100% / 7);
			height: calc(100vw / 7);
		}

		.static-thumbnail-caption {
			position: absolute;
			width: 65.85%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.icon {
				font-size: 3em;
			}

			p {
				display: inline-block;
				margin: 0;
				padding: 5px 0;
				color: $white;
				font-size: 1.05em;
				@media (min-width: 1600px) {
					font-size: 1.2em;
				}
			}
			p:last-child {
				font-weight: bold;
			}
		}

		@include lgDesktop {
			.static-thumbnail-caption {

				.icon {
					font-size: 3.2em;
				}

				p {
					font-size: 1.05em;
				}
			}
		}
		@media (min-width: 1700px) {
			.static-thumbnail-caption {

				.icon {
					font-size: 4em;
				}

				// p {
				// 	font-size: 1.65em;
				// }
			}
		}
	}

	.mobile-thumbnail {
		display: none;
	}

	@include mobile {
		.static-thumbnail {
			width: 100%;
			height: 15vh;
			background-size: cover;
			background-position: center;
			float: left;
			margin: 0;
			padding: 0;
			position: relative;

			.static-thumbnail-caption {
				position: absolute;
				width: 80%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.insta-icon, .hashtag {
					display: inline-block;
					height: 100%;
					vertical-align: middle;
				}

				.insta-icon {
					width: 35%;
					text-align: center;
					font-size: 1.3em;
				}

				.hashtag {
					width: 60%;
				}

				p {
					margin: 0;
					padding: 5px 0;
					color: $white;
					font-size: 1.1em;
				}
				p:last-child {
					font-weight: bold;
				}
			}
		}

		.thumbnail {
			width: 33.333333%;
			height: 33.333333vw;
		}

		.thumbnail-caption {
			font-size: 73%;
			padding: 50px 10px 10px 10px;
		}
		img {
			display: block;
		}

		.mobile-thumbnail {
			display: block;
		}

		.desktop-thumbnail {
			display: none;
		}

		.thumbnail:nth-child(10), .thumbnail:nth-child(11),
		.thumbnail:nth-child(12) {
			display: none;
		}
	}

	@include tablet {
		.static-thumbnail {
			width: 100%;
			height: 15vh;
			background-size: cover;
			background-position: center;
			float: left;
			margin: 0;
			padding: 0;
			position: relative;

			.static-thumbnail-caption {
				position: absolute;
				width: 80%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.insta-icon, .hashtag {
					display: inline-block;
					height: 100%;
					vertical-align: middle;
				}

				.insta-icon {
					width: 35%;
					text-align: center;
					font-size: 1.3em;
				}

				.hashtag {
					width: 60%;
				}

				p {
					margin: 0;
					padding: 5px 0;
					color: $white;
					font-size: 1.1em;
					display: block;
				}
				p:last-child {
					font-weight: bold;
				}
			}
		}

		.thumbnail {
			width: 33.333333%;
			height: 33.333333vw;			
		}

		.thumbnail-caption {
			padding: 50px 10px 15px 15px;
		}

		.mobile-thumbnail {
			display: block;
		}

		.desktop-thumbnail {
			display: none;
		}

		.thumbnail:nth-child(10), .thumbnail:nth-child(11),
		.thumbnail:nth-child(12) {
			display: none;
		}
	}
}

@include desktop {
	.thumbnail {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}

	.thumbnail-caption {
		opacity: 0;
		padding: 50px 10px 17px 23px;
	}

	.thumbnail:hover {
		-webkit-filter: none;
		filter: none;
		transition: all 0.5s;

		.thumbnail-caption {
			opacity: 1;
			transition: all 0.5s;
		}
	}
}

.lgDesktopOnly {
	display: none;
	@media (min-width: 1600px){
		display: block;
	}
}