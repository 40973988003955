// table-responsive styles
/* table-responsive styles*/
.table-responsive {
	max-width: 100%;
	margin-bottom: 15px;
    padding: 0 10px;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    > table {
        // min-height: .01%;
        border-collapse: collapse;
        height: 100px;
        overflow-x: auto;
    }
}