.rate-our-website.container {

  @include custom-mobile {
    margin-top: 115px;
  }

  form {
    margin-bottom: 40px;


  }

  label {
    &:first-child {
      font-size: 1.85em;
      font-weight: bold;
      display: block;
      margin-bottom: 30px;
    }
  }

  .text-box {
    width: 50%;
    border: 1px solid #000;
    border-radius: 0px;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;

    @include custom-mobile {
      width: 100%;
    }
  }

  .control-label {
    display: block;
    padding-bottom: 15px;
  }
  .radio {
    display: inline-block;
    margin-right: 18px;

    label {
      font-size: initial;
      font-weight: normal;
    }

    input[type="radio"] {
      margin-right: 0;
      -webkit-appearance: radio;
      appearance: radio;
    }

    @include custom-mobile {
      margin-right: 15px;
    }
  }

  .form-submit-border {
    margin-top: 20px;
    display: block;

    .btn {
      border: 1px solid transparent;
      display: block;
      height: 42px;
      line-height: 42px;
      padding: 0 10px;
      border-radius: 0px;
      font-size: 0.9em;
      background: #000;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      zoom: 1;
    }
  }
}
