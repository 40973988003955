// banner styles

.banner {
    position: relative;

    height: 656px;
    margin-top: -75px;

    background-position: center;
    // height: 54.666667vw;
    background-size: cover;


    &__text-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 50%;

        background-color: rgba(38, 88, 53, .95);

        .banner__title {
            border-bottom: 1px solid $white;
        }

        .banner__desc {
            font-size: 1em;
            line-height: 1.6em;

            max-width: 580px;
            margin: 25px 0;
            padding: 0;
        }

        .banner__link {
            font-size: 1em;
            // padding: 0 0 6px 0;
            font-weight: bold;

            display: inline-block;

            cursor: pointer;
            transition: color 300ms;
            text-decoration: none;
            text-transform: uppercase;

            color: $white;

            .icon {
                padding-left: 5px;
            }

            &:hover {
                color: $lightgrey;
            }
        }


        .banner__box {
            // width: 43.166667vw;
            // height: 29.666667vw;
            // position: relative;
            display: inline-block;

            width: 100%;
            height: 100%;
            margin: 0;
            padding: 15px 0 40px 40px;

            color: $white;
            // border-top: 3px solid $pri-green;

            h1 {
                font-size: 1.7em;
                font-weight: bold;

                max-width: 580px;
            }
        }
    }
}

@include mobile {
    .banner {
        position: relative;

        height: 42vh;
        min-height: 300px;
        margin: 0;
        margin: 73px 0 0 0;

        &__text-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;
            padding: 0;

            background-color: $black-70;

            .banner__box {
                font-size: .9em;

                width: 100%;
                max-width: none;
                height: 100%;
                margin: 0;
                padding: 15px 7% 0 7%;

                color: $white;
                border: none;


                h1 {
                    font-size: 1.8em;

                    color: $white;
                }
            }
        }
    }
}

@include tablet {
    .banner {
        position: relative;

        height: 42vh;
        min-height: 300px;
        margin: 0;
        margin: 73px 0 0 0;


        &__text-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;
            padding: 0;

            background-color: $black-70;

            .banner__box {
                font-size: .97em;

                width: 100%;
                max-width: none;
                height: 100%;
                margin: 0;
                padding: 20px 30% 0 45px;

                color: $white;
                border: none;


                h1 {
                    font-size: 1.95em;

                    color: $white;
                }
            }
        }
    }
}
