/* plan-trip styles*/

#plan-trip-dialog {
		background-color: rgba(0,0,0,0.75);
		width: 100%;
		height: 100%;
		display: none;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 5000;

		.dialog-inner {
			max-width: 700px;
			width: 90%;
			padding: 25px;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			        transform: translate(-50%, -50%);
			box-shadow: 0px 2px 6px rgba(0,0,0,1);
			background-color: rgb(241, 240, 237);
		}

		a {
			text-decoration: none;
			font-weight: bold;
			color: $pri-green;
		}

		.popup-close {
			text-decoration: none;
			width: 30px;
			height: 30px;
			border-radius:1000px;
			padding-top: 4px;
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			-webkit-transition: ease 0.25s all;
			transition: ease 0.25s all;
			-webkit-transform: translate(50%, -50%);
			        transform: translate(50%, -50%);
			font-size: 20px;
			text-align: center;
			line-height: 100%;
			color: #fff;
			background: rgba(0,0,0,0.8);

			&:hover {
				-webkit-transform:translate(50%, -50%) rotate(180deg);
				        transform:translate(50%, -50%) rotate(180deg);
				background:rgba(0,0,0,1);
			}
		}
	}

.plan-trip-container {
	background-position: center;
	background-repeat: repeat;
	height: auto;
	position: relative;

	.plan-trip-header {
		text-align: center;
		padding: 70px 0 0;

		h2, p {
			margin: 0;
			padding: 7px 0;
		}
	}
	@include mobile {
		.plan-trip-header {
			padding: 25px 0 13px;
		}
	}
	@include tablet {
		.plan-trip-header {
			padding: 25px 0 13px;
		}
	}
}

.plan-trip-cards-wrapper {
	width: 100%;
	position: relative;
	overflow: auto;

	.card-container {
		overflow: auto;
		padding: 0 0 117px 0;
	}

	@include mobile {
		.card-container {
			overflow: auto;
			padding: 10px 0 30px;
		}
	}

	@include tablet {
		.card-container {
			overflow: auto;
			padding: 10px 15px;
			margin: 0;
		}
	}
}

.operatingHours, .textCard {
	display: none;
}

.cards:nth-child(6) {
	display: none;
}
.cards {
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	overflow: hidden;
}

.dynamicCards {
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 0;
}

.small-card.video:hover {
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	box-shadow: 0 0 20px rgba(160, 160, 160, 1);
	cursor: pointer;
	-webkit-transform: translate(3px, -3px);
	        transform: translate(3px, -3px);
}

.dynamicCards .cards img {
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

.dynamicCards .cards:hover {
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	box-shadow: 0 0 20px rgba(160, 160, 160, 1);
	-webkit-transform: translate(3px, -3px);
	        transform: translate(3px, -3px);
	/* img {*/
	/* 	transition: all 0.5s;*/
	/* 	-ms-transform: scale(1.1, 1.1); /* IE 9 */
	/* 	-webkit-transform: scale(1.1, 1.1); /* Safari */
	/* 	transform: scale(1.1, 1.1);*/
	/* }*/
}

@media (hover:none), (hover:on-demand) {
	.small-card.video:hover {
		-webkit-transition: all 0.5s;
		transition: all 0.5s;
		box-shadow: 0 0 20px rgba(160, 160, 160, 1);
		cursor: pointer;
		-webkit-transform: translate(3px, -3px);
		        transform: translate(3px, -3px);
	}
	.dynamicCards .cards:hover {
		-webkit-transition: all 0.5s;
		transition: all 0.5s;
		box-shadow: 0 0 20px rgba(160, 160, 160, 1);
	}
}

/* .cards:first-child, .cards:last-child,*/
/* .cards:nth-child(2) {*/
/* 	transition: none;*/
/* 	box-shadow: none;*/
/* }*/

.videoCard, .imageCard, .bigImageCard, .textCard {
	> a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.emergencyNotices {
	a.btn {
		text-decoration: none;
		color: $white;
		width: 90%;
		text-align: center;
		display: block;
		margin: 25px auto 20px;
		background-color: $pri-green;
		padding: 10px;
		border-radius: 5px;
	}

	a.btn:hover {
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		box-shadow: 2px 2px 2px $grey;
	}

	@media (hover:none), (hover:on-demand) {
		a.btn:hover {
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			box-shadow: 2px 2px 2px $grey;
		}
	}
}

@media (min-width: 961px) and (max-width: 1220px) {
	.operatingHours, .videoCard,
	.imageCard, .textCard,
	.bigImageCard, .emergencyNotices {
		font-size: 88%;
	}

	.slick-dots {
		li {
			margin: 0;
			text-indent: 999px;
			overflow: hidden;
			border: 2px solid $icon-brown;
			border-radius: 7px;
			padding: 0;
			margin: 0 3px;
			width: 8px;
			height: 8px;
		}

		.slick-active {
			background-color: $icon-brown;
		}
	}
	.emergencyNotices {
		a.btn {
			text-decoration: none;
			color: $white;
			width: 90%;
			text-align: center;
			display: block;
			margin: 10px auto 5px;
			background-color: $pri-green;
			padding: 10px;
			border-radius: 5px;
		}

		a.btn:hover {
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			box-shadow: 2px 2px 2px $grey;
		}
		@media (hover:none), (hover:on-demand) {
			a.btn:hover {
				-webkit-transition: all 0.3s;
				transition: all 0.3s;
				box-shadow: 2px 2px 2px $grey;
			}
		}
	}
}

.slides-group {
	margin: 0 auto 0 !important;
	width: 90%;
	max-height: calc(100% - 83px);

	.slides {

		h3 {
			color: $icon-brown;
			margin: 10px 0;
			font-size: 1.6em;
		}

		p {
			margin: 10px 0;
			font-size: 0.875em;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				list-style-type: none;
				display: inline-block;
				padding: 0 35px 0 0;

				p {
					margin: 0;
				}
			}
		}
		.notice-email {
			color: $notice-email-color;
			text-decoration: none;
		}
	}

	.slick-dots {
		li {
			margin: 0;
			text-indent: 999px;
			overflow: hidden;
			border: 1px solid $icon-brown;
			border-radius: 7px;
			padding: 0;
			margin: 0 3px;
			width: 10px;
			height: 10px;
		}

		.slick-active {
			background-color: $icon-brown;
		}
	}
}

@include desktop {

	.plan-trip-header {
		text-align: center;
		padding: 25px 0 0;

		h2, p {
			margin: 0;
			padding: 5px 0;
		}

		.title {
			font-weight: 900;
			margin: 0;
			font-size: 1.8em;
		}

		p {
			font-size: 1.3em;
			color: $weather-text;
		}
	}

	.imageCard, .bigImageCard {
		img {
			height: 100%;
			width: 100%;
		}
	}

	.plan-trip-tabs {
		position: relative;
		text-align: center;

		ul {
			display: inline-block;
			list-style-type: none;
			padding: 0;
			/* width: 715px;*/
			height: 130px;
			margin: 4px auto 29px;

			li {
				display: inline-block;
				padding: 5px 20px;
				margin: 10px 0 20px;
				cursor: pointer;

				.areaIcon {
					display: block;
					margin-left: auto;
					margin-right: auto;
				}

				.activeIcon, .mobileIcon {
					display: none;
				}

				p {
					text-align: center;
					font-weight: bold;
					color: $icon-brown;
					margin-bottom: 0;
					padding: 0 5px 7px;
				}
			}

			// li:nth-child(3) {
			// 	img {
			// 		width: 45px;
			// 	}
			// }

			.active {

				p {
					color: $pri-green;
					border-bottom: 3px solid $pri-green;
				}

				.areaIcon {
					display: none;
				}

				> .activeIcon {
					display: block;
				}
			}
		}
	}

	.cards {
		float: left;
		margin: 8px;
	}

	.small-card {
		display: inline-block;
		width: calc(25% - 16px);
		padding-top: calc(25% - 16px);
		position: relative;
	}

	.operatingHours, .textCard,
	.cards:nth-child(6) {
		display: block;
	}



	.operatingHours, .videoCard,
	.imageCard, .textCard,
	.bigImageCard, .emergencyNotices {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img:first-child {
			height: 100%;
			width: 100%;
		}
	}

	.operatingHours {
		padding: 0 0 0 30px;
		ul {
			padding: 0;
			li {
				display: block;
				padding: 0 0 20px 0;
				p {
					margin: 0;
				}
			}
		}

		h3 {
			color: $icon-brown;
			margin: 12px 0 20px 0;
			font-size: 2em;
		}

		h3:first-line {
			font-size: 80%;
			font-weight: bold;
		}

		p {
			margin: 0 0 20px 0;
			font-size: 0.875em;
		}
	}

	.videoCard {
		.play-icon {
			width: 25%;
			height: 25%;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			        transform: translate(-50%, -50%);
		}
	}

	.image-caption-tag {
		background-color: $white;
		position: absolute;
		bottom: 0;
		right: 0;
		max-width: 200px;

		.category {
			text-transform: uppercase;
			border-bottom: 2px solid $pri-green;
			margin: 0 0 0 20px;
			font-size: 0.75em;
			padding: 15px 20px 13px 0;
		}

		.caption {
			padding: 13px 20px;
			margin: 0;
			font-size: 1.2em;
		}
	}

	.textCard {
		background-color: $white;

		.text-info-card {
			width: 85%;
			h3 {
				margin-right: 30px;
				padding: 38px 26px 20px;
				margin: 0;
				border-bottom: 2px solid $pri-green;
				font-weight: normal;
			}

			p {
				line-height: 20px;
				padding: 20px 5px 10px 26px;
				margin: 0;
			}

			p:last-child {
				text-decoration: none;
				color: $pri-green;
				font-weight: bold;
				padding: 0 0 0 26px;
				text-transform: uppercase;
			}
		}
	}

	.big-card {
		display: inline-block;
		width: calc(50% - 16px);
		padding-top: calc(25% - 16px);
		position: relative;
	}

}

@include mobile {
	.plan-trip-header {
		.title {
			font-weight: 900;
			margin: 0;
			font-size: 1.3em;
		}

		p {
			font-size: 1em;
			color: $weather-text;
		}
	}

	.plan-trip-tabs {
		position: relative;
		text-align: center;
		ul {
			display: inline;
			list-style-type: none;
			padding: 0;
			width: 95%;
			margin: 10px auto;

			li {
				display: inline-block;
				width: 30%;
				padding: 10px 0;
				margin: 5px 0;
				cursor: pointer;
				border: 1px solid $icon-brown;


				.areaIcon {
					/* display: none;*/
					height: 30px;
				}

				.activeIcon, .mobileIcon {
					display: none;
				}

				p {
					text-align: center;
					font-weight: normal;
					color: $icon-brown;
					margin: 0;
					font-size: 0.9em;
				}
			}

			/* li:nth-child(3) {*/
			/* 	img {*/
			/* 		width: 45px;*/
			/* 	}*/
			/* }*/

			.active {
				padding: 9px 0;
				border: 2px solid $pri-green;
				background-color: $pri-green;

				p {
					color: $white;
				}

				.areaIcon {
					display: none;
				}

				> .mobileIcon {
					display: inline-block;
				}
			}
		}
	}

	.mobile-cards {
		display: inline-block;
		width: 100%;
		height: 25vw;
		min-height: 100px;
		overflow: hidden;
		position: relative;
		margin: 5px 0;

		img:first-child {
			width: 25vw;
			height: 25vw;
			min-width: 100px;
			min-height: 100px;
		}

		.play-icon {
			min-width: none;
			min-height: none;
			width: 37px;
			height: 37px;
			position: absolute;
			top: 50%;
			left: 12.5vw;
			-webkit-transform: translate(-50%, -50%);
			        transform: translate(-50%, -50%);
			cursor: pointer;
		}

		@media (max-width: 400px) {
			.play-icon {
				min-width: none;
				min-height: none;
				width: 37px;
				height: 37px;
				position: absolute;
				top: 50%;
				left: 50px;
				-webkit-transform: translate(-50%, -50%);
				        transform: translate(-50%, -50%);
				cursor: pointer;
			}
		}

		.image-caption-tag {
			width : calc(100% - 25vw);
			max-width: calc(100% - 100px);
			height: 25vw;
			min-height: 100px;
			position: absolute;
			right: 0;
			top:0;
			background-color: $white;

			p {
				margin: 0 0 0 25px;
				padding: 10px 0 10px 0;
			}

			p:first-child {
				text-transform: uppercase;
				border-bottom: 2px solid $pri-green;
				padding: 20px 0 10px 0;

			}
		}
	}

	.dynamicCards .mobile-cards:nth-child(1),
	.dynamicCards .mobile-cards:nth-child(5) {
		img {
			position: absolute;
			right: 0;
		}
		.image-caption-tag {
			left: 0;
		}
	}

	.emergencyNotices {
		/* display: none;*/
		.slide-0 {
			padding: 0;

			h1 {
				color: $icon-brown;
			}

			ul {

				li {
					display: block;
					padding: 0 0 10px 0;
					p {
						margin: 0;
					}
				}
			}
		}
	}

}

@include tablet {
	.plan-trip-header {
		.title {
			font-weight: 900;
			margin: 0;
			font-size: 1.7em;
		}

		p {
			font-size: 1.2em;
			color: $weather-text;
		}
	}

	.plan-trip-tabs {
		position: relative;
		text-align: center;
		ul {
			display: inline;
			list-style-type: none;
			padding: 0;
			width: 90%;
			margin: 10px auto;

			li {
				display: inline-block;
				width: 30%;
				padding: 10px 0;
				margin: 5px 2px;
				cursor: pointer;
				border: 2px solid $icon-brown;


				.areaIcon {
					/* display: none;*/
					height: 30px;
				}

				.activeIcon, .mobileIcon {
					display: none;
				}

				p {
					text-align: center;
					font-weight: bold;
					color: $icon-brown;
					margin: 0;
				}
			}

			/* li:nth-child(3) {*/
			/* 	img {*/
			/* 		width: 45px;*/
			/* 	}*/
			/* }*/

			.active {
				border: 2px solid $pri-green;
				background-color: $pri-green;

				p {
					color: $white;
				}

				.areaIcon {
					display: none;
				}

				> .mobileIcon {
					display: inline-block;
				}
			}
		}
	}

	.cards {
		float: left;
	}

	.big-card {
		max-width: 100%;
	}

	.card-container {
		display: inline-block;
		width: 100%;
		padding: 10px 30px;
		margin: 10px;
	}

	.mobile-cards {
		display: inline-block;
		width: calc(50% - 10px);
		height: 12.5vw;
		overflow: hidden;
		position: relative;
		margin: 5px 5px;

		img {
			width: 12.5vw;
			height: 12.5vw;
		}

		.play-icon {
			width: 37px;
			height: 37px;
			position: absolute;
			top: 50%;
			left: 6.25vw;
			-webkit-transform: translate(-50%, -50%);
			        transform: translate(-50%, -50%);
			cursor: pointer;
		}

		.image-caption-tag {
			width : calc(100% - 12.5vw);
			height: 12.5vw;
			position: absolute;
			right: 0;
			top:0;
			background-color: $white;

			p {
				margin: 0 0 0 25px;
				padding: 10px 0 10px 0;
			}

			p:first-child {
				text-transform: uppercase;
				border-bottom: 2px solid $pri-green;
				padding: 20px 0 10px 0;
			}
		}

	}

	.emergencyNotices {
		/* display: none;*/
		.slide-0 {
			padding: 0;

			h1 {
				color: $icon-brown;
			}

			ul {

				li {
					display: block;
					padding: 0 0 10px 0;
					p {
						margin: 0;
					}
				}
			}
		}
	}
}


@include lgDesktop {

	.textCard {
		background-color: $white;

		.text-info-card {
			width: 85%;
			h3 {
				margin-right: 30px;
				padding: 38px 32px 20px;
				margin: 0;
				border-bottom: 2px solid $pri-green;
				font-weight: normal;
			}

			p {
				line-height: 20px;
				padding: 20px 5px 20px 32px;
				margin: 0;
			}

			p:last-child {
				color: $pri-green;
				font-weight: bold;
				padding: 0 0 0 30px;
				text-transform: uppercase;
			}
		}
	}
}