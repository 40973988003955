// openmap styles
.diywalks-body-wrapper {
	width: 100%;
	background-color: $standard-bg;
}

.map-header {
	padding: 40px 30px 42px;
	@include desktop {
	padding: 40px 0 42px 0;

	}
	h1 {
		margin: 0;
		padding: 0 0 14px 0;
		font-size: 1.3em;
		@include desktop {
			font-size: 1.85em;
		}
	}

	p {
		margin: 0;
		padding: 0;
		max-width: 750px;
		font-size: 0.875em;
		@include desktop {
			font-size: 1em;
		}
	}
}

.onemap-container {
	width: 100%;
	height: 685px;
	@include mobile {
		height: 440px;
		padding: 7px 30px 23px;
	}
	// background-color: $grey;
	position: relative;
	font-family: $primaryfont;
	line-height: 1;
	font-size: 16px;

	#mapdiv {
		width: 100%;
		height: 100%;
	}
}


.leaflet-container {
	font-family: $primaryfont;
	line-height: 1;
	font-size: 100%;
}

.leaflet-left .leaflet-control {
	margin: 582px 0 30px 60px;
	@include mobile {
		margin: 10px 0 0 7px;
	}
	a {
		text-decoration: none;
		color: #666666;
		padding: 6px 2px;
		width: 36px;
		height: 36px;
		font-size: 1.5em;
		font-weight: 900;
		@include mobile {
			width: 27px;
			height: 27px;
			padding: 0;
			font-size: 1.3em;

		}
	}
}

.leaflet-bar a:first-child {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}
.leaflet-bar a:last-child {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

.leaflet-container a.leaflet-popup-close-button {
	display: none;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
	border-radius: 0px;
}

.leaflet-popup-content {
	width: auto !important;
}

.leaflet-popup-content p {
	margin: 0;
	line-height: 1;
}

.leaflet-popup-content .main-popUp-title {
	color: $map-orange;
	margin: 5px 0;
	font-size: 1.1em;
}

.main-popUp-title {
	width: 130px;
}

.main-popUp-category {
	text-transform: uppercase;
}

.amenity-popUp {
	text-align: center;
	color: $map-blue;
	width: 130px;
}

.trail-popUp {
	text-align: center;
	width: 130px;
}

.map-filter-wrapper {
	display: none;
	@include desktop {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2000;
		padding: 50px 0 0 60px;
	}
}

.map-filter {
	background-color: $mapFilters-bg;
	width: 325px;
	margin: 0 0 15px 0;

	.select2-container--default .select2-selection--single {
		background-color: $clear-white;
		border: none;
		border-bottom: 2px dotted $black;
		height: auto;
	}

	.select2-container .select2-selection--single .select2-selection__rendered {
		margin: 0;
		padding: 0 0 0 3px;
		line-height: 1;
		font-weight: bold;
		font-style: italic;
	}

	.select2-container--default .select2-selection--single .select2-selection__arrow {
		margin: 0;
		height: 14px;
		width: 10px;
	}

	.select2-container--default .select2-results__option--highlighted[aria-selected] {
		background-color: $lightgrey;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			float: left;
			display: inline;
			padding: 3px 12px;
			margin: 5px;
			border: 1px solid $grey;
			font-size: 0.92em;
			color: $grey;
			cursor: pointer;
		}
	}

	ul:after {
		content: '';
		clear: both;
		display: table;
	}
}

.map-accordion {
	display: none;
	padding: 0 18px 26px;
}

.filter-head {
	padding: 13px 23px;
	font-size: 0.875em;

	.icon {
		vertical-align: middle;
		color: $grey;
		float: right;
		cursor: pointer;
	}

	span.visuallyhidlden {
		float: right;
	}
}

.rotateIcon {
	transform: rotate(180deg);
}

.leaflet-sidebar {

	@include mobile {
		max-width: 430px;
		width: 100%
	}
	width: 430px;
	padding: 0;


	> .leaflet-control {
		border-radius: 0;
		padding: 0;
	}
}

.map-slide-bar {
	background-color: rgba(255, 255, 255, 0.95);
	overflow: auto;
	@include desktop {
		width: 430px;
	}
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	box-shadow: 0 0 15px rgba(160, 160, 160, 0.4);
	z-index: 2000;

	h3, h5, p {
		margin: 0;
		padding: 0;
		font-weight: normal;
	}
}

.slide-bar-content {
	padding: 0 0 0 47px;
}

.close-btn-container {
	width: 344px;
	@include mobile {
		width: 100%;
		padding: 0 35px 0 0;
	}
	.close-btn {
		color: $lightgrey;
		font-size: 2em;
		height: 55px;
		padding: 24px 0 8px;
		float: right;
		cursor: pointer;

		.halfcross1, .halfcross2 {
			width: 25px;
			height: 5px;
			border-radius: 3px;
			background-color: $lightgrey;
		}

		.halfcross1 {
			transform: rotate(45deg) translate(2px, 1.8px);
		}
		.halfcross2 {
			transform: rotate(-45deg) translate(1.8px, -1.8px);
		}
	}
}

.close-btn-container:after {
	content: '';
	clear: both;
	display: table;
}

.slide-img {
	width: 344px;
	height: 194px;
	overflow: hidden;
	@include mobile {
		width: 100%;
		height: 38vw;
		max-height: 190px;
		padding: 0 35px 0 0;
	}

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.slide-details h3 {
	font-size: 1.5em;
	padding: 36px 0 0 0;
	@include mobile {
		font-size: 1.3em;
	}
}

.slide-details h5 {
	border-bottom: 2px solid $map-orange;
	padding: 5px 0 20px 0;
	text-transform: uppercase;
	font-size: 0.875em;
}

.slide-details p {
	padding: 28px 0 8px 0;
	width: 90%;
	text-align: left;
	font-size: 0.875em;
	line-height: 1.4;
	@include mobile {
		font-size: 0.8em;
	}
}

.slide-details a {
	text-decoration: none;
	font-weight: bold;
	text-transform: uppercase;
	color: $map-orange;
	font-size: 0.9em;
	@include mobile {
		font-size: 0.82em;
	}
}

.slide-suggestions {
	padding: 35px 0 0 0;

	p {
		font-size: 1em;
		padding: 0 0 18px 0;
	}

	.suggestions {
		overflow: hidden;
		padding-bottom: 20px;

		img {
			width: 160px;
			height: 90px;
			width: auto;
			// height: auto;
			max-width: 30%;
			padding: 0 20px 0 0;
			float: left;
			cursor: pointer;

			@include mobile {
				// width: 100px;
				// height: 100px;
				// width: 110px;
				height: 62px;
			}
		}
	}
	.suggestions:after {
		content: '';
		clear: both;
		display: table;
	}
}

.select2-container {
	z-index: 2001;
}

.mobile-filters {
	position: relative;
	margin: 0 0 25px 0;
	@include desktop {
		display: none;
	}
	ul {
		list-style-type: none;
	}

	p {
		text-align: center;
		margin: 22px;
	}
}

.mobile-filter-tabs {
	position: relative;
	z-index: 2501;
	color: $pri-green;
	ul {
		padding: 0;
		margin: 0;
	}
	li {
		width: calc(100%/3);
		text-align: center;
		border: 1px solid $pri-green;
		height: 40px;
		padding: 10px 0;
		position: relative;
	}
	li:nth-child(2) {
		border-right: none;
		border-left: none;
	}

	li:hover {
		cursor: pointer;
	}

	li.active {
		color: $white;
		background-color: $pri-green;
		&:after {
			content: '';
			width: 0;
			height: 0;
			border-top: 8px solid $pri-green;
			border-right: 8px solid $clear-white;
			border-left: 8px solid $clear-white;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
}

.mobile-filter-tabs:after {
	content: '';
	clear: both;
	display: table;
}

.mobile-filter-buttons {
	width: 280px;
	margin: auto;

	ul {
		padding: 0;
		margin: 0;

		li {
			width: 126px;
			height: 40px;
			text-align: center;
			padding: 11px 0;
			margin: 5px 7px;
			color: $grey;
			border: 1px solid $grey;
			font-size: 0.9em;
			cursor: pointer;
		}

		li:hover
	}
}

.mobile-interest-button {
	width: 294px;

	ul li {
		width: 90px;
		margin: 5px 4px;
		font-size: 0.85em;
	}
}

.mobile-filter-buttons:after {
	content: '';
	clear: both;
	display: table;
}


.mobile-filter-close p {
	color: $pri-green;
	font-weight: bold;
	margin: 28px;
	cursor: pointer;
}

.interest-list .active, .mobile-interest .active {
	background-color: $map-orange;
	color: $white;
	border: 1px solid $map-orange;
}

.trails-list .active, .mobile-trail .active {
	color: $white;
}

// .trails-list .active:first-child, .mobile-trail .active:first-child {
// 	background-color: $black;
// 	color: $white;
// 	border: 1px solid $black;
// }

// .trails-list .active:nth-child(2), .mobile-trail .active:nth-child(2) {
// 	background-color: $map-trails-blue;
// 	color: $white;
// 	border: 1px solid $map-trails-blue;
// }

// .trails-list .active:nth-child(3), .mobile-trail .active:nth-child(3) {
// 	background-color: $map-trails-orange;
// 	color: $white;
// 	border: 1px solid $map-trails-orange;
// }

// .trails-list .active:last-child, .mobile-trail .active:last-child {
// 	background-color: $map-trails-green;
// 	color: $white;
// 	border: 1px solid $map-trails-green;
// }

.amenities-list .active, .mobile-amenity .active {
	background-color: $map-blue;
	color: $white;
	border: 1px solid $map-blue;
}

.mobile-accordion {
	display: none;
	width: 100%;
	position: absolute;
	left: 0;
	top: 100%;
	z-index: 2500;
	background-color: $mapFilters-bg;
}
