// standardpage-slider styles
.slider {
    position: relative;

    &__item {
        position: relative;

        height: 400px;

        color: $body-text;

        @include mobile {
            height: 300px;
        }
    }

    .slider__desc {
        font-size: calc-em(14px);

        position: absolute;
        bottom: 0;

        width: 100%;
        padding: 15px;

        background: rgba(237, 237, 237, .95);
    }

    .slick-arrow {
        line-height: 1;

        position: absolute;
        z-index: 2;

        width: 40px;
        height: 40px;

        cursor: pointer;

        border: none;
        background: $pri-green;

        @include vertical-align;

        .icon {
            font-size: 1.4em;

            color: $white;
        }
    }

    .slide-next {
        right: 0;
    }

    .slide-prev {
        left: 0;
    }

    .slick-dots {
        padding: 15px 0;

        text-align: center;

        button {
            font-size: 0;

            display: inline-block;

            width: 10px;
            height: 10px;
            padding: 0;

            border: none;
            border: 1px solid $pri-green;
            background: none;

            @include border-radius(50%);
        }

        li {
            display: inline-block;

            margin: 5px;

            border: 2px solid $pri-green;
        }

        .slick-active {
            background-color: $pri-green;
        }
    }
}

.fou-slider {
    position: relative;

    .slick-slide {
        padding: 10px;

        .slider__item {
            position: relative;

            cursor: pointer;

            &:after {
                position: absolute;
                top: 0;
                left: 0;

                display: block;

                width: 100%;
                height: 100%;

                content: '';

                background: rgba(0,0,0,.5);
            }
        }


        &.slick-current {
            .slider__item {
                &:after {
                    display: none;
                }
            }
        }
    }

    .slider__item {
        position: relative;

        height: 650px;

        @include mobile {
            height: 300px;
        }
    }

    .slick-arrow {
        background: transparent;


        .icon {
            font-size: 2.6em;

            color: $white;
        }
    }

    .slider__desc {
        font-size: calc(14px);

        position: absolute;
        right: 0;
        bottom: -14px;

        max-width: 500px;

        background: $white;

        h5 {
            font-size: calc-em(20px, 14px);

            margin: 0;
            padding: 5px 0 15px;

            border-bottom: 2px solid $pri-green;
        }
    }
}
