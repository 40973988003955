// Normalize Styles
@import 'node_modules/normalize.css/normalize';
@import 'node_modules/jquery-slinky/dist/jquery.slinky';
@import 'node_modules/select2/dist/css/select2';
@import 'node_modules/pikaday/scss/pikaday';
@import 'node_modules/leaflet/dist/leaflet';
@import 'node_modules/leaflet-sidebar/src/L.Control.Sidebar.scss';
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/fullcalendar/dist/fullcalendar';
@import 'node_modules/bulma/sass/utilities/_all';
@import 'node_modules/bulma/sass/components/modal';

// Import Modules
@import './fonts';
@import './variables';
@import './mixins';
@import './icons';
@import './rateourwebsite';

// Vendor
@import './vendor/_slick';
@import './vendor/_slick-theme';

// @import '../_modules/organisms/site-header/site-header';
@import '../_modules/molecules/slider/slider';
@import '../_modules/organisms/banner/banner';
@import '../_modules/organisms/weather/weather';
@import '../_modules/organisms/plan-trip/plan-trip';
@import '../_modules/organisms/activity-calendar/activity-calendar';
@import '../_modules/organisms/social-gallery/social-gallery';
@import '../_modules/organisms/site-footer/site-footer';
@import '../_modules/organisms/standard-body/standard-body';
@import '../_modules/organisms/standard-header/standard-header';
@import '../_modules/organisms/contactform/contactform';
@import '../_modules/organisms/newsroom/newsroom';
@import '../_modules/organisms/friends-of-ubin/friends-of-ubin';
@import '../_modules/organisms/webform/webform';
@import '../_modules/organisms/onemap/onemap';
@import '../_modules/organisms/eventslisting/eventslisting';
@import '../_modules/organisms/search/search';
@import '../_modules/molecules/pagination/pagination';
@import '../_modules/molecules/table-responsive/table-responsive';
@import '../_modules/molecules/calendar/calendar';
// @import '../_modules/link/link';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
[class*='col-'] {
    float: left;
}

.col-xs-1 {
    width: 8.33%;
}
.col-xs-2 {
    width: 16.66%;
}
.col-xs-3 {
    width: 25%;
}
.col-xs-4 {
    width: 33.33%;
}
.col-xs-5 {
    width: 41.66%;
}
.col-xs-6 {
    width: 50%;
}
.col-xs-7 {
    width: 58.33%;
}
.col-xs-8 {
    width: 66.66%;
}
.col-xs-9 {
    width: 75%;
}
.col-xs-10 {
    width: 83.33%;
}
.col-xs-11 {
    width: 91.66%;
}
.col-xs-12 {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .col-sm-1 {
        width: 8.33%;
    }
    .col-sm-2 {
        width: 16.66%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-4 {
        width: 33.33%;
    }
    .col-sm-5 {
        width: 41.66%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-7 {
        width: 58.33%;
    }
    .col-sm-8 {
        width: 66.66%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-10 {
        width: 83.33%;
    }
    .col-sm-11 {
        width: 91.66%;
    }
    .col-sm-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 961px) {
    .col-md-1 {
        width: 8.33%;
    }
    .col-md-2 {
        width: 16.66%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-4 {
        width: 33.33%;
    }
    .col-md-5 {
        width: 41.66%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-7 {
        width: 58.33%;
    }
    .col-md-8 {
        width: 66.66%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-10 {
        width: 83.33%;
    }
    .col-md-11 {
        width: 91.66%;
    }
    .col-md-12 {
        width: 100%;
    }
}


*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
// body, a {
//   font-family: $primaryfont;
// }

body,
a {
    font-family: $primaryfont;
}

body {
    &.stop-scrolling {
        overflow: hidden;

        height: 100%;
    }
}

.logo {
    margin-top: 15px;
}

// Styling on warning for users on IE7 or below
.browsehappy {
    margin: 0;
    padding: .5em 0;

    text-align: center;

    color: #000;
    background: #fff8e2;
}


.container {
    position: relative;

    max-width: 1220px;
    height: auto;
    margin: 0 auto;
    padding: 0 10px;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

img {
    max-width: 100%;
}

input {
    -webkit-border-radius: 0;
            border-radius: 0;
}

input {
    -webkit-appearance: none;
}

a {
    cursor: pointer;
    text-decoration: none;

    color: $pri-green;
}

.visuallyhidden {
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;
}

.page-editor-editable-image {
    position: absolute;
    top: 120px;
    right: 15px;

    width: 150px;
    height: auto;

    border: 3px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}

.notclickable {
    cursor: default;
    pointer-events: none;
}

//Captcha image styling

.scfForm .scfCaptcha img {
    width: 100%;
    min-width: 0;
}

// Added new Class
.standard-content {
    form.text-left {
        // display: none;

        &.showForm {
            display: block !important;
        }
    }
}

//object-fit class
.object-fit {
    width: 100%;
    height: 100%;

    object-fit: cover;
}


.scfForm.plForm {
    margin: 40px 30px;
    padding: 15px 0;

    border-top: solid 2px;
    border-top-color: #000;
    background: #f7f7f7;

    .scfSingleLineTextLabel {
        display: block;
        float: left;
        clear: both;

        padding: 3px 0;
    }

    .validation-summary-errors,
    .field-validation-error {
        display: block;

        color: #f00;
    }

    .scfDropList {
        width: 100%;
        max-width: 485px;
        padding: 0 0 0 18px;
    }

    .scfMultipleLineTextBox {
        max-width: 485px;
        padding: 10px 18px 0;
    }

    .scfSingleLineTextBox {
        max-width: 485px;
        padding: 0 0 0 18px;
    }
}
