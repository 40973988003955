

// site-footer styles
.footer-background {
  height: auto;
  width: 100%;
  background-color: $footer-green;

  img {
    width: 100%;
  }

}

.footer-wrap {

  color: $white;
  width: 100%;
  padding: 46px 0 20px;

  .footer-wis {
    padding-left: 138px;

    small {
      border-right: 2px solid lightgrey;
      padding: 0 14px;
    }

    small:first-child {
      padding-left: 0;
    }

    small:last-child {
      border: none;
    }
  }

  .ubin-legal-column {
    display: inline-block;
    margin: -3px 0 0 0;
    position: relative;
    width: 100%;

    .footer__logo {
      padding: 0 0 0 5px;
      margin: 0 0 40px 0;
    }
    .footer__legal {
      margin: 0 0 20px 0;
      padding: 0 0 0 60px;
      font-weight: bold;

      p {
        padding: 0;
        margin: 0;
        font-size: 0.875em;
      }

      .last-update {
        clear: both;
        position: relative;
        top: 10px;
        float: none;
      }

      ul {
        padding: 0;
        margin: 5px 0;

        li {
          border-right: 2px solid lightgrey;
          padding: 0;
          margin: 0;
          display: inline;
          float: left;

          a {
            padding: 0 14.5px;
            text-decoration: none;
            color: $white;
            font-weight: normal;
            font-size: 0.8em;
          }

          a:hover {
            color: $text-hover;
          }
        }
        li:first-child {
          a {
            padding: 0 14.5px 0 0;
          }
        }
        li:last-child {
          border: none;
        }
      }
    }
    .social-column {
      position: absolute;
      right: 10px;

      .footer__social {
        margin: -5px 0 0 0;
        vertical-align: middle;
        display: inline-block;

        .social-label {
          display: block;
          padding: 0 0 18px 10px;
          margin: auto;
          font-size: 0.875em;
        }

        .social-link-group {
          display: inline-block;
          vertical-align: middle;

          .social-link {
            display: inline-block;
            text-decoration: none;
            color: $white;
            padding: 0 18px 0 0;
            font-size: 2.2em;
          }
          .social-link:last-child {
            padding: 0;
          }
        }
      }
    }
  }

  .ubin-lega-column:after {
    content: '';
    clear: both;
    display: table;
  }
}

.footer-wrap:after {
  content: '';
  clear: both;
  display: table;
}

.footer__iframe {
  height: 100px;
  width: 320px;
}


@include mobile {

  .footer__subscribe {
    margin-top: 30px;
    text-align: center;
  }

  // .m-subscribe {
  //   text-align: center;
  //   width: 304px;
  //   margin: 0 auto;
  //   padding: 20px 0 0 0;

  //   label {
  //     padding: 0 0 15px 0;
  //   }

  //   #m-subscribe-button {
  //     width: 105px;
  //     font-size: 0.8em;
  //   }
  // }

  .footer {
    padding: 0;
  }

  .footer-wrap {
    display: none;
  }

  .m-footer {
    font-size: 1em;
    color: $white;

    p {
      text-align: center;
      margin: 0;
      padding: 20px 0 10px;
      font-size: 0.875em;
    }

    .m-footer-logo {

      p {
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding: 25px 0 20px;
      }
    }

    ul {
      margin: 5px 0 0 0;
      position: relative;
      text-align: center;
      padding: 0;
      li {
        list-style-type: none;
        display: inline;
        padding: 0 10px 0 5px;
        margin: 10px 0;
        border-right: 2px solid $white;

        a {
          color: $white;
          text-decoration: none;
          font-size: 0.875em;
        }
      }

      li:last-child {
        border-right: none;
      }
    }

    .m-social-media {
      margin: 0;
      padding: 0 0 10px 0;
      //Added Clear
      clear: both;

      li {
        border: none;

        a {
          font-size: 2.4em;
        }
      }
    }

    .m-last-update,
    .m-supported-browsers {
      text-align: center;
      padding-bottom: 10px;
    }

    .m-legal {
      padding: 0 12% 30px;
    }


  }

  .subscribe-field {
    width: 198px;
  }

}

@include tablet {

  .footer__subscribe {
    // width: 324px;
    margin-top: 30px;
    text-align: center;

    form {
      // text-align: center;
      // width: 304px;
      // margin: 0 auto;
      padding: 20px 0 0 0;

      label {
        padding: 0 0 15px 0;
      }
    }
  }

  // .m-subscribe {
  //   text-align: center;
  //   width: 327px;
  //   margin: 0 auto;
  //   padding: 20px 0 0 0;

  //   label {
  //     padding: 0 0 15px 0;
  //   }

  //   #m-subscribe-button {
  //     width: 105px;
  //     font-size: 0.8em;
  //   }
  // }

  .footer-wrap {
    display: none;
  }

  .m-footer {
    font-size: 1.1em;
    color: $white;

    p {
      text-align: center;
      margin: 0;
      padding: 20px 0 10px;
      font-size: 0.875em;
    }

    .m-footer-logo {

      p {
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding: 25px 0 20px;
      }
    }

    ul {
      margin: 5px 0 0 0;
      position: relative;
      text-align: center;
      padding: 0;

      li {
        list-style-type: none;
        display: inline;
        padding: 0 10px 0 5px;
        margin: 10px 0;
        border-right: 2px solid $white;

        a {
          color: $white;
          text-decoration: none;
          font-size: 0.875em;
        }
      }

      li:last-child {
        border-right: none;
      }
    }

    .m-social-media {
      margin: 0;
      padding: 0 0 10px 0;
      //Added Clear
      clear: both;

      li {
        border: none;

        a {
          font-size: 2.4em;
        }
      }
    }

    .m-last-update,
    .m-supported-browsers {
      text-align: center;
      padding-bottom: 10px;
    }

    .m-legal {
      padding: 0 15% 30px;
    }
  }

}

@include desktop {
  .m-footer {
    display: none;
  }

  .footer__subscribe {
    padding: 0 160px 0 60px;
      margin-bottom: 10px;

    #email {
      width: 250px;
    }

    #subscribe-button {
      width: 107px;
      font-size: 0.9em;
    }
  }
}

.modal {
  z-index: 5001; // above header

  .modal-content {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 80vh;
    width: 80vw;
    max-width: 100%;
    #generatedImg {
      display: none;
    }
    @include mobile {
      width: 100vw;
      background-size: 100%;
      background-color: transparent;
      #generatedImg {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
