/* standard-body styles*/


.standard-body-wrapper {
    position: relative;

    width: 100%;
    margin: 73px 0 0 0;

    background-color: $standard-bg;
}

.standard-content {
    p {
        font-size: .875em;
    }

    a {
        font-weight: bold;
        /* font-size: 0.875em;*/

        text-decoration: none;

        color: $pri-green;

        &:hover {
            border: none;
        }
    }
}

.standard-main-wrap::after {
    display: table;
    clear: both;

    content: '';
}

.breadcrumb {
    display: none;

    padding: 40px 0 0;

    ul {
        clear: both;
        overflow: hidden;

        margin: 0;
        padding: 0;

        li {
            display: inline;
            float: left;

            padding: 0;

            a {
                font-size: .8em;

                text-decoration: none;
                text-transform: uppercase;

                color: $black;
            }

            a:hover {
                text-decoration: underline;
            }

            .breadcrumb-active {
                font-weight: bold;

                color: $pri-green;
            }

            .breadcrumb-active:hover {
                text-decoration: none;
            }
        }

        li + li:before {
            padding: 10px;

            content: '\25B8';

            color: $black;
        }
    }
}

.standard-main-wrap {
    display: flex;
}

.standard-main {
    display: inline-block;

    width: 100%;
    padding: 10px 20px 15px;

    text-align: left;

    h1 {
        font-size: 1.75em;
        font-weight: bold;

        margin: 0;
        padding: 15px 0 10px;
    }

    .standard-content {
        img {
            display: block;

            width: auto;
            max-width: 100%;
            height: auto !important;
            padding: 20px 0;
        }
    }

    h3 {
        margin: 0;
        padding: 15px 0 15px;
    }

    p {
        margin: 0;
        padding: 10px 0 10px;
    }
}

.social-share {
    margin: 10px 0;
    ul {
        margin: 0;
        padding: 0;

        list-style: none;
    }

    li {
        display: inline-block;

        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }

        a {
            font-weight: normal;
            line-height: 22px;

            height: 22px;

            -webkit-transition: color .25s ease-in-out, background .25s ease-in-out, border .25s ease-in-out;
                    transition: color .25s ease-in-out, background .25s ease-in-out, border .25s ease-in-out;
            text-decoration: none;

            color: #000;
            border: 1px solid #ececec;
            border-radius: 2px;
            background: #fff;

            .icon {
                padding: 0 5px 0 5px;

                &-facebook {
                    color: $facebook;
                }

                &-twitter {
                    color: $twitter;
                }

                &-envelop {
                    color: $mail;
                }
            }

            .social-title {
                font-size: 12px;
                line-height: 18px;

                display: inline-block;

                height: 22px;
                padding-right: 10px;

                vertical-align: middle;
            }

            &:hover {
                .icon {
                    color: #fff;
                }
            }
        }

        .btn-fb {
            &:hover {
                @include socialHover($facebook);
            }
        }

        .btn-twitter {
            &:hover {
                @include socialHover($twitter);
            }
        }

        .btn-email {
            &:hover {
                @include socialHover($mail);
            }
        }
    }
}

.sidenav {
    display: none;
    float: left;

    width: 285px;
    min-height: 315px;
    padding: 44px 0 0 0;

    .level1 {
        padding: 0;

        list-style-type: none;

        border-left: 2px solid $pri-green;

        .level1list {
            margin: 0 0 0 -4px;
            padding: 10px 0;

            a {
                font-size: .875em;

                padding: 0 0 0 26px;

                text-decoration: none;

                color: $black;
            }

            a:hover {
                color: $pri-green;
            }
        }
        .upArrow {
            font-size: 1.4em;

            display: none;

            padding: 0 0 0 15px;

            vertical-align: middle;

            color: $pri-green;
        }

        .downArrow {
            font-size: 1.4em;

            padding: 0 0 0 15px;

            vertical-align: middle;
        }

        .sideNav-active {
             > a {
                padding: 0 0 0 20px;

                color: $pri-green;
                border-left: 6px solid $pri-green;
            }
            .downArrow {
                display: none;

                padding: 0 0 0 15px;
            }

            .upArrow {
                display: inline;
            }

            .level2 {
                display: block;

                .sub-sideNav-active {
                     > a {
                        color: $pri-green;
                    }
                }
            }
        }
        .level2 {
            display: none;

            padding: 0;

            list-style-type: none;

            .level2list {
                padding: 5px 0;
                a {
                    font-size: .83em;

                    padding: 0 0 0 36px;
                }
            }
            .level2list:first-child {
                padding: 17px 0 5px;
            }
        }
        li:first-child {
            padding: 0 0 10px 0;
        }
        .level1list:last-child {
            padding-bottom: 0;
        }
    }
}

@include desktop {
    .standard-body-wrapper {
        margin: 0;
    }
    .standard-main {
        width: 60.83333vw;
        max-width: 730px;
        padding: 54px 0 100px 0;

        h1 {
            padding: 0 0 10px 0;
        }

        .standard-content {
            img {
                width: auto;
                min-width: 0;
                padding: 10px 0 20px;
            }
        }
    }

    .sidenav {
        display: inline-block;
    }
    .breadcrumb {
        display: block;
    }
}
