// eventslisting styles

.event-listing-main {
	@include desktop {
		max-width: 915px;
		width: 66.4932362vw;
	}
	@include mobile {
		padding: 0 0 15px;
	}
	@include tablet {
		padding: 0 0 15px;
	}
}

.events-container {
	position: relative;
	margin: 0 auto;
	max-width: 1220px;
	padding: 0;
	height: auto;

	@include desktop {
		padding: 0 10px;
	}
}

// .events-padded {
// 	@include mobile {
// 		padding-right: 30px;
// 		padding-left: 30px;
// 	}
// 	@include tablet {
// 		padding-right: 30px;
// 		padding-left: 30px;
// 	}
// }

.calendar-text {
	h1 {
		@include mobile {
			margin: 41px auto;
			width: 280px;
			text-align: center;
		}
		@include tablet {
			margin: 58px 0;
			text-align: center;
		}
	}
}

.eventcards-wrapper {
	width: 100%;
	margin: 28px 0 0 0;
	@include desktop {
		margin: 0;
	}
}

.eventcards-wrapper:after {
	content: '';
	clear: both;
	display: table;
}

.events-links {
	margin: 0 0 15px 0;
	width: 100%;
	border-bottom: 1px solid $lightgrey;
	@include desktop {
		float: right;
		border-top: 1px solid $lightgrey;
	}

	.links-container {
		@include desktop {
			float: right;
		}
	}

	a {
		text-decoration: none;
		color: $pri-green;
		font-weight: bold;
		display: inline-block;
		vertical-align: middle;
		font-size: 0.875em;
		@include mobile {
			width: 50%;
			text-align: center;
			padding: 0;
		}
		@include tablet {
			width: 50%;
			text-align: center;
			padding: 0;
		}
		@include desktop {
			padding: 7px 0 7px 45px;
		}
		.icon {
			height: 30px;
			width: 30px;
			font-size: 1.3em;
			display: inline-block;
			vertical-align: middle;
		}

		.icon-star {
			padding: 5px 0 0 6px;
		}

		.icon-download {
			font-size: 1.7em;
			padding: 3px 0 0 6px;

		}

		p {
			display: inline-block;
			vertical-align: middle;
			text-align: left;
			@include mobile {
				width: 80px;
				font-size: 0.8em;
			}
			@include tablet {
				width: 250px;
				padding: 15px 0;
			}
		}
	}

	a:first-child {
		border-right: 1px solid $lightgrey;
		@include desktop {
			padding: 7px 0 7px 5px;
			border-right: none;
		}
	}
}

.events-links:after {
	content: '';
	clear: both;
	display: table;
}

.events-filter {
	clear: both;
	@include mobile {
		margin: 0 30px;
	}
	@include tablet {
		margin: 0 30px;
	}
}

.filter-box {
	display: inline-block;
	float: left;
	margin: 0 10px 0 0;
	position: relative;

	.filter-select {
		border: 0;
		padding: 0;
		margin: 0;
		width: 0;
		height: 0;
		overflow: hidden;
		position: absolute;
		background: 0 0;
		text-indent: 999px;
	}

	.default-option {
		text-transform: capitalize;
		text-align: left;
		padding: 12px 15px;
		display: inline-block;
		width: 100%;
		height: 40.5px;
		border: 1px solid $lightgrey;
		vertical-align: middle;
		position: relative;
		font-size: 0.875em;
	}

	.default-option:after {
		content: '';
		display: block;
		position: absolute;
		right: 15px;
		top: 18px;
		width: 0;
		height: 0;
		padding: 0;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: 4px solid $black;
	}
	// .default-option.open:after {
	// 	border-top: 0;
	// 	border-bottom: 4px solid $black;
	// }

	.filter-options {
		transition: all 0.4s;
		opacity: 0;
		position: absolute;
		left: 0;
		top: 36px;
		z-index: 0;
		background-color: $white;
		display: block;
		width: 100%;
		font-size: 0.875em;
		visibility: hidden;
		box-shadow: 0 0 8px rgba(160, 160, 160, 0.7);
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		a {
			text-transform: capitalize;
			text-decoration: none;
			padding: 6px 15px;
			display: inline-block;
			width: 100%;
			text-align: left;
		}
		a:hover {
			background-color: $pri-green;
			color: $white;
		}

		li:first-child a {
			padding: 12px 15px;
			display: inline-block;
			width: 100%;
			height: 40.5px;
			vertical-align: middle;
			position: relative;
		}
		li:first-child a:after {
			content: '';
			display: block;
			position: absolute;
			right: 15px;
			top: 18px;
			width: 0;
			height: 0;
			padding: 0;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-bottom: 4px solid $black;
		}
	}
}

.filter-box {
	> p {
		font-size: 0.875em;
	}

	li:last-child {
		a {
			padding-bottom: 15px;
		}
	}
}

.custom-date {
	// margin: 10px 0 0 0;
	display: none;
	padding: 5px 15px 25px;
	border-top: 1px solid $lightgrey;

	.custom-date-input {
		padding: 6px 0;

		label {
			font-size: 0.9em;
			padding: 10px 0;
		}
	}

	label {
		display: inline-block;
	}

	input {
		max-width: 135px;
		height: 40px;
		padding: 0 10px;
		float: right;

		@include mobile {
			max-width: none;
			width: calc(100% - 60px);
		}
	}
}

button.calendar-filter {
	margin-top: 36px;
	position: relative;
	z-index: 1;
}


.events-filter:after {
	content: '';
	clear: both;
	display: table;
}

.filter-date {
	width: 220px;
}

.filter-event {
	width: 260px;
}

.filter-visitor {
	width: 230px;
	@include tablet {
		margin: 0;
	}
}

.filter-date, .filter-event, .filter-visitor {
	@include mobile {
		width: 100%;
	}
	@include tablet {
		width: calc(100%/3 - 7px);
	}
}

.eventcards-display-result {
	display: none;
	@include desktop {
		display: block;
	}
	p {
		padding: 30px 0 20px;
	}
}

.event-card {
	transition: all 0.3s;
	position: relative;
	display: block;
	float: left;
	width: 100%;
  background: $white;

	@include tablet {
		width: calc(33.333333% - 14px);
	}
	@include desktop {
		width: calc(33.333333% - 14px);
	}
	position: relative;
	margin: 0 14px 32px;
	@include mobile {
		margin: 0 0 32px;
	}
	cursor: pointer;

	img {
		margin: 0;
		display: block;
		width: 100%;
	}

	.fav {
		position: absolute;
		top: 0;
		right: 0;
		width: 36px;
		height: 36px;
		background-color: $white;
		z-index: 2;

		.icon {
			position: absolute;
			top: 47%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: $grey;
			font-size: 1.3em;
		}
	}
	.fav:hover {
		.icon {
			color: $pri-green;
		}
	}

	@media (hover:none), (hover:on-demand) {
		.fav:hover {
			.icon {
				color: $grey;
			}
		}
	}
	.fav.active {
		.icon {
			color: $pri-green;
		}
	}

	.event-details {
		background-color: $white;
		margin: 0;
		display: block;
		padding: 18px 15px 1px 15px;
		min-height: 195px;

		h3 {
			margin: 0;
			padding: 0 0 20px 0;
			font-size: 1.03em;
			text-align: left;
		}

		p {
			margin: 0;
			padding: 0;
			display: inline-block;
			font-size: 0.85em;
			width: calc(100% - 35px);
			text-align: left;
		}

		.icon {
			display: inline-block;
			color: $pri-green;
			font-size: 1.15em;
			padding: 0 12px 0 0;
			vertical-align: top;
			width: 30px;
		}

		.detail {
			margin: 0 0 8px 0;
			width: 100%;
		}

		.detail:last-child {}
	}

	.event-date-time {
		display: inline-block;
		p {
			width: 100%;
			display: block;
		}
	}

	> a {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
}

// .event-card:hover {
// 	transition: all 0.3s;
// 	box-shadow: 0 0 20px rgba(160, 160, 160, 1);
// 	h3 {
// 		color: $pri-green;
// 	}
// }

.event-card.active {
	transition: all 0.3s;
	box-shadow: 0 0 20px rgba(160, 160, 160, 1);
	h3 {
		color: $pri-green;
	}
}

@media (hover:none), (hover:on-demand) {
	// .event-card:hover {
	// 	box-shadow: 0 0 20px rgba(160, 160, 160, 1);
	// 	h3 {
	// 		color: $pri-green;
	// 	}
	// }
	.event-card.active {
		box-shadow: 0 0 20px rgba(160, 160, 160, 1);
		h3 {
			color: $pri-green;
		}
	}
}

.event-card:nth-child(1), .event-card:nth-child(4) {
	margin: 0 7px 32px 0;
	@include mobile {
		margin: 0 0 32px 0;
	}
}

.event-card:nth-child(3), .event-card:nth-child(6) {
	margin: 0 0 32px 7px;
	@include mobile {
		margin: 0 0 32px 0;
	}
}

.pika-lendar {
	position: absolute;
	z-index: 11;
	background-color: $white;
	text-align: center;
	padding: 10px 10px 20px;
	@media (max-width: 380px) {
		left: -45px;
		top: 0;
	}

	.pika-table {
		thead {
			background-color: $pri-green;
			color: $white;
			tr {
				height: 32px;

				th {
					padding: 0 0 3px 0;
				}
			}

			abbr {
				text-decoration: none;
				color: $white;
			}
		}

		tbody {
			color: $white;

			tr {
				height: 32px;
				padding: 5px 0;
			}
			td:hover {


				button {
					color: $white;
					background-color: $pri-green;
				}
			}
		}
	}
}

.favoriteEvent-modal {
	// display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 10000001;
	padding: 60px 0;
	top: 0;
	left: 0;
	background-color: $modal-bg;
	opacity: 0;
	transition: all 0.5s;
	visibility: hidden;
}

.modal-content {
	width: 60%;
	@include mobile {
		min-width: 290px;
	}
	min-width: 675px;
	max-width: 1050px;
	max-height: 100%;
	margin: auto;
	padding: 0 25px 45px;
	background-color: $standard-bg;
	overflow-y: auto;
	border-radius: 3px;

	h1 {
		display: inline-block;
		text-transform: capitalize;
		padding: 0;

		@include mobile {
			font-size: 1.2em;
			display: block;
		}
	}

	span {
		text-transform: uppercase;

		.icon-close {
			font-size: 1.6em;
			color: $grey;
			padding: 0;
			cursor: pointer;
		}
	}

	.fav-header {
		width: 100%;
		position: relative;
		margin: 0 0 45px 0;

		span {
			position: absolute;
			right: 0;
			bottom: 0;
			color: $pri-green;
			font-weight: bold;
			padding: 30px 0 0 0;
			font-size: 0.8em;
			cursor: pointer;
			@include mobile {
				bottom: -30px;
				left: 0;
			}
		}
	}
}

.modal-close {
	padding: 15px 0;
}

.modal-close:after {
	content: '';
	clear: both;
	display: table;
}

.fav-events {
	width: 100%;
	height: auto;
}

.fav-events:after {
	content: '';
	clear: both;
	display: table;
}
