// friends-of-ubin styles

.friends-ubin {
    line-height: 1.5em;

    &__banner {
        position: relative;

        height: 600px;
    }

    &__banner-text {
        position: absolute;
        bottom: 0;

        width: 100%;
        padding: 10px 0 20px;

        text-align: center;

        background: rgba(255, 255, 255, .95);
    }

    &__title {
        font-size: calc(32px);
    }
    &__desc {
        max-width: 768px;
        margin: 0 auto;
    }

    .key-thrusts {
        width: 100%;
        padding: 30px 0;

        color: $white;
        background: $pri-green;

        &__title {
            font-size: calc(28px);

            margin: 0;
        }

        &__desc {
            // max-width: 768px;
            // margin: 0 auto;
        }

        &__link {
            font-weight: bold;

            display: block;

            transition: color 300ms;
            vertical-align: middle;
            text-transform: uppercase;

            color: $white;

            &:hover {
                color: $lightgrey;
            }
        }

        .icon {
            font-size: 1em;

            padding-left: calc-em(14px);

            vertical-align: middle;
        }

        &:after {
            display: table;
            clear: both;

            content: '';
        }

        &__list {
            display: flex;

            list-style-type: none;

            @include tablet {
              flex-wrap: wrap;
            }
        }

        &__item {
            padding: 5px 10px;

            text-align: center;

            @include tablet {
              width: 30%;
            }
            a {
                transition: color 300ms;

                color: $white;

                &:hover {
                    color: $lightgrey;
                }
            }
            span {
                display: block;

                margin: 10px auto;
            }
        }
    }

    .highlights {
        position: relative;

        background-repeat: no-repeat;
        background-size: cover;

        &__overlay {
            position: absolute;

            width: 100%;
            height: 100%;

            background: rgba(240, 239, 237, .8);
        }

        &__content {
            position: relative;

            padding: 30px 0;
        }


        &__title {
            font-size: calc(28px);

            margin: 0;
            padding: 30px 0 20px;

            text-align: center;
        }

        &__desc {
            margin: 0;
            padding: 0 10px 30px;

            text-align: center;
        }

        &__wrapper {
            display: flex;

            flex-wrap: wrap;
            // justify-content: space-between;
        }

        &__item {
            width: 32.3%;
            margin: 10px .5%;
            padding-bottom: 30px;

            transition: all 200ms ease;
            transition-property: visibility, height, opacity;

            background: $white;

            &-image {
                height: 200px;
            }

            &-title {
                width: calc(100% - 20px);

                h4 {
                    margin: 0;
                    padding: 20px 20px 15px;

                    border-bottom: 1px solid $pri-green;
                }
            }

            &-desc {
                line-height: 1.5em;

                margin: 0;
                padding: 10px 20px;
            }

            &-link {
                font-weight: bold;

                display: block;

                padding: 10px 20px;

                transition: color 300ms ease;
                text-transform: uppercase;

                &:hover {
                    color: $grey;
                }
            }

            &.hidden {
                visibility: hidden;

                height: 0;
                margin: 0;
                padding: 0;

                opacity: 0;
            }
        }

        &__showMore {
            margin: 0 auto;

            text-align: center;
        }

        &__showMoreBtn {
            margin: 5px 0 20px;
            padding: 7px 12px;

            cursor: pointer;
            transition: color 300ms ease;

            color: $white;
            border: 1px solid $pri-green;
            border-radius: 5px;
            background: $pri-green;
            &:hover {
                color: $lightgrey;
            }
        }
    }


    .fou-slideshow {
        &__desc {
            line-height: 1.5em;

            max-width: 768px;
            margin: 0 auto;
            padding: 50px 0;

            text-align: center;
        }
    }
}

@include mobile {
    .friends-ubin {
        .key-thrusts {
            width: 100%;
            padding: 30px 0;

            color: $white;
            background: $pri-green;

            &__title {
                font-size: calc(28px);

                margin: 0;
            }


            &__link {
                font-weight: bold;

                display: block;

                text-transform: uppercase;

                color: $white;
            }

            &__list {
                margin: 0;
                padding: 30px 0 0;

                list-style-position: inside;

                flex-wrap: wrap;
            }


            &__item {
                width: 50%;
                padding: 5px;

                text-align: center;
                a {
                    color: $white;
                }
                span {
                    display: block;

                    margin: 10px auto;
                }
            }
        }

        .highlights {
            &__item {
                width: 100%;

                &-image {
                    height: 150px;
                }
            }
        }
    }
}
