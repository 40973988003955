// // weather styles

.weather-wrapper {
	width: 100%;
	background-color: $weather-off-white;

}

.weather-container {
	// width: 940px;
	margin: auto;
	background-color: $weather-off-white;
}

.tide-link {
	padding: 0;
	margin: 55px 0;
	display: inline-block;
	width: 20%;

	a {
		text-decoration: none;
		color: $pri-green;

		.tide-link {
			display: inline;
			position: relative;

			p {
				color: $pri-green;
				display: block;
				font-weight: bold;
				padding: 1px 0 0 0;
				position: relative;
				max-width: 105px;
			}

			p:after {
				content: '';
				display: block;
				width: 82%;
				position: absolute;
				bottom: 0;
				height: 1px;
				opacity: 0;
				background-color: $pri-green;
			}

			p:last-child:after {
				content: '';
				display: block;
				width: 87%;
				position: absolute;
				bottom: 0;
				height: 1px;
				opacity: 0;
				background-color: $pri-green;
			}
		}
		.icon {
			display: inline;
			vertical-align: middle;
			margin-left: 0;
			padding: 20px 0 0 0;
			opacity: 1;
			font-weight: bold;
		}
	}

	a:hover {
		// transition: ease-in 0.5s;
		// .icon {
		// 	// transition: ease-in 0.5s;
		// 	margin-left: 5px;
		// 	opacity: 1;
		// }
		p:after, p:last-child:after  {
			transform: scale(1, 2);
			// transition: ease-in 0.3s;
			opacity: 1;
		}
	}

}

.weather-info, .tide-info, .tide-link {
	display: inline-block;
	vertical-align: middle;
}

.weather-info {
	width: 45%;
	border-right: 1px solid $lightgrey;
	padding: 0 15px 0 0;
	margin: 0 0 0 15px;
}

.date-info, .weather-icon, .temperature-info {
	display: inline-block;
	vertical-align: top;

	p {
		margin: 0;
	}

	.day {
		font-weight: bold;
		font-size: 1.2em;
	}

	.date {
		font-size: 0.9em;
	}

	.temperature {
		font-size: 3em;
		padding: 3px 0 0 0;
	}
	.weather-state {
		color: $weather-text;
		font-size: 0.9em;
	}
}

.temperature-info {
	width: 110px;
}

.temperature-info {
	margin: 3px 0 0 0;
}

.weather-icon {
	width: 135px;
	margin: 0 7px 0 0;
}

.high-tide, .low-tide {
	display: inline-block;
	text-align: right;
	padding: 3px 0 0 16px;
}

.low-tide {
	padding: 0 0 0 36px
}

.h-time, .l-time {
	display: inline;
	padding: 0 0 0 5px;
	font-size: 1.1em;
}

.height {
	font-size: 2.6em;
	margin: 0;
	padding: 0;

	span {
		font-size: 20px;
		font-weight: bold;
		margin: 0 -20px 0 0;
	}
}

.tide-des {
	color: $weather-text;
	margin: 0;
	font-size: 0.9em;
}

@include mobile {
	.tide-info, .tide-link {
		display: none;
	}

	.weather-info {
		margin: 20px 0;
	}
	.accordion-panel {
		display: none;
	}

	.m-accordion {
		border: none;
		padding: 2px 0 0 0;
		background-color: $clear-white;
		margin: 8px 14px;
		text-align: center;

		img {
			width: 25px;
			height: 27px;
		}
	}

	.m-accordion img:hover {
		transition: all 0.5s;
		transform: rotate(180deg);
	}

	.tide-info, .tide-link {
		display: none;
	}

	.weather-container {
		width: 100%;
	}

	.weather-info {
		display: block;
		margin: 0 auto;
		padding: 10px 0;
		border: none;
		width: 100%;
		text-align: center;
		vertical-align: middle;

		.date-info {
			margin: 10px 3px 0 0;
			width: 85px;
			min-width: 85px;
		}

		.day {
			font-size: 1em;
		}

		.date {
			font-size: 0.7em;
			margin: 0 0 0 -17px;
		}

		.weather-icon {
			width: 60px;
			margin: 6px 6px 0 3px;
		}

		.temp {
			display: none;
		}
		.temperature-info {
			width: 63px;
		}
		.temperature-info, .temperature {
			margin: 2px 0 0;
			padding: 0;
		}

		.temperature {
			font-size: 1.6em;
			.temp-symbol {
				font-size: 70%;
			}
		}

		.weather-state {
			font-size: 0.6em;
			margin: 0;
			min-width: 100px;
			text-align: left;
		}

	}

	.m-tide-info {
		display: inline-block;
		vertical-align: middle;
		padding: 10px 0 10px 0;
	}

	.high-tide, .low-tide {
		padding: 15px 0 0 0;
		text-align: left;

		img {
			width: 27px;
		}
	}
	.high-tide {
		padding: 0 36px 0 0;
	}

	.low-tide {
		padding: 0;
	}

	.h-time, .l-time {
		font-size: 0.85em;
		padding: 0;
	}

	.tide-des {
		font-size: 0.6em;
		padding: 0 0 0 18px;
	}

	.height {
		font-size: 1.6em;
		padding: 0 0 0 13px;

		span {
			font-size: 20px;
			font-weight: normal;
			margin: 0 -17px 0 0;
		}
	}

	.l-height {
		padding: 0 0 0 30px;
		margin: 0 0 0 -18px;
		border-left: 1px solid $mobile-icon;
	}

	.m-tide-link {
		padding: 0;
		margin: 5px 0 12px;
		display: block;
		vertical-align: middle;

		a {
			text-decoration: none;
			color: $pri-green;
			font-size: 0.9em;
			padding: 0 0 5px 0;

			.m-tide-link {
				display: inline;
				p {
					color: $pri-green;
					display: inline;
					font-weight: bold;
					padding: 0;
				}
			}
			.icon {
				display: inline;
				vertical-align: middle;
			}
		}

		a:hover {
			border-bottom: 2px solid $pri-green;
		}
	}
}

@include tablet {
	.accordion-panel {
		display: none;
	}

	.m-accordion {
		border: none;
		padding: 2px 0 0 0;
		background-color: $clear-white;
		margin: 14px;
		text-align: center;

		img {
			width: 36px;
			height: 38px;
		}
	}

	.m-accordion img:hover {
		transition: all 0.5s;
		transform: rotate(180deg);
	}

	.tide-info, .tide-link {
		display: none;
	}

	.weather-container {
		width: 100%;
	}

	.weather-info {
		display: block;
		margin: 0 auto;
		padding: 20px 30px;
		border: none;
		width: 80%;
		text-align: center;
		vertical-align: middle;

		.date-info {
			margin: 10px 0 0 0;
			min-width: 110px;
		}

		.date {
			margin: 0 0 0 -23px;
		}

		.weather-icon {
			width: 80px;
			margin: 9px 5px 0 20px;
		}

		.temp {
			display: none;
		}

		.temperature-info, .temperature {
			margin: 0;
			padding: 0;
		}

		.temperature {
			font-size: 2.5em;
		}

	}

	.m-tide-info {
		display: inline-block;
		vertical-align: middle;
		padding: 10px 0 10px 0;
	}

	.high-tide, .low-tide {
		padding: 15px 0 0 0;
		text-align: left;

		img {
			width: 27px;
		}
	}
	.high-tide {
		padding: 0 36px 0 0;
	}

	.low-tide {
		padding: 0;
	}

	.h-time, .l-time {
		font-size: 1em;
		padding: 0;
	}

	.tide-des {
		font-size: 0.9em;
		padding: 0 0 0 18px;
	}

	.height {
		font-size: 2.6em;
		padding: 0 0 0 5px;

		span {
			font-size: 20px;
			font-weight: normal;
			margin: 0 -17px 0 0;
		}
	}

	.l-height {
		padding: 0 0 0 18px;
		margin: 0 0 0 -18px;
		border-left: 1px solid $mobile-icon;
	}

	.m-tide-link {
		padding: 0;
		margin: 5px 0 12px;
		display: block;
		vertical-align: middle;

		a {
			text-decoration: none;
			color: $pri-green;
			font-size: 0.9em;
			padding: 0 0 6px 0;

			.m-tide-link {
				display: inline;
				p {
					color: $pri-green;
					display: inline;
					font-weight: bold;
					padding: 0;
				}
			}
			.icon {
				display: inline;
				vertical-align: middle;
			}
		}

		a:hover {
			border-bottom: 2px solid $pri-green;
		}
	}
}

@include desktop {

	.tide-info {
		width: 35%;
	}

	.tide-link {
		max-width: 15%;
		p {
			padding: 0 0 2px 0;
		}
	}

	.m-accordion {
		display: none;
	}
	.accordion-panel {
		display: none;
	}
	.date-info {
		margin: 0 20px 0 0;
	}
}

@media (min-width: 961px) and (max-width: 1079px) {
	.weather-info {
		margin: 20px 0 20px 15px;
	}

	.tide-link {
		p {
			margin: 0 0 0 38px;
		}
	}
}

@media only screen and (min-width: 1080px) {
	.weather-container {
		width: 1060px;
	}
	.weather-info {
		margin: 20px 0 20px 0;
		padding: 0 40px 0 0;
	}

	.high-tide, .low-tide {
		padding: 2px 0 0 36px;
	}

	.low-tide {
		padding: 0 0 0 56px
	}

	.tide-link {
		margin: 29px 5px 29px 0;
		p {
			margin: 0 0 0 50px;
		}
	}

	.high-tide, .low-tide {
		img {
			width: 40px;
		}
	}
}

@include lgDesktop {
	.weather-container {
		width: 1366px;
		padding: 0 0 0 30px;
	}
	.weather-info {
		padding: 0 0 0 0;
		width: 580px;
	}
	.date-info {
		margin: 0 20px 0 0;
		font-size: 110%;
		min-width: 121px;
	}

	.tide-info {
		width: auto;
	}

	.weather-icon {
		margin: 0 20px 0 23px;
	}

	.temperature-info  {
		margin: 0 0 0 44px;
	}

	.high-tide {
		padding: 2px 0 0 51px;
	}

	.low-tide {
		padding: 2px 0 0 76px
	}

	.tide-link {
		p {
			color: $pri-green;
			display: inline;
			font-weight: bold;
			margin: 0 0 0 79px;
		}
	}
}
