// contactform styles
.contactform-wrapper {
	width: 100%;
	background-color: $standard-bg;
	margin: 73px 0 0 0;
	padding: 33px 0 0 0;
	@include desktop {
		margin: 0;
		padding: 0;
		
		h1 {
			padding: 0 0 10px 0;
		}
	}
}
.contactform-body {
	font-size: 0.875em;
}

.scfForm .select2-container .select2-selection--single {
	height: 50px;
	border-radius: 0px;
	border-color: $lightgrey;
}

.scfForm .select2-container .select2-selection--single .select2-selection__rendered {
	margin: 11px 0;
}

.scfForm .select2-container--default .select2-selection--single .select2-selection__arrow {
	margin: 12px 9px 12px 0;
}

.scfForm .scfCaptcha img {
	display: block;
}

.contact-details {
	max-width: 485px;

	p {
		margin: 0;
		padding: 2px 0;
	}

	p:first-child {
		font-weight: bold;
		font-size: 1.15em;
	}

	.tel {
		padding: 17px 0 3px;
	}

	.address {
		padding: 7px 7px 0 0;
		// float: left;
	}
}

.contact-details:after {
	content: '';
	clear: both;
	display: table;
}