// pagination styles
.pagination {
	position: relative;
	margin: auto;
	padding: 0 0 15px 0;
	@include desktop {
		// margin: 0;
		// padding: 28px 0 0 168px;
		padding: 28px 0 0;
	}
	a {
		display: inline-block;
		text-decoration: none;
		color: black;
		width: 30px;
		height: 30px;
		float: left;
		padding: 5px;
		text-align: center;
		margin: 0 0 0 7px;
		font-size: 0.875em;
		padding-top: 7px;
	}
	a:first-child {
		margin: 0;
	}

	// a:nth-child(3), a:nth-child(4), a:nth-child(5) {
	// 	font-size: 0.875em;
	// 	padding-top: 7px;
	// }

	// a:nth-child(6), a:nth-child(7), a:nth-child(8) {
	// 	font-size: 0.875em;
	// 	padding-top: 7px;
	// 	display: none;
	// 	@include desktop {
	// 		display: inline-block;
	// 	}
	// }

	> .active {
		background-color: $pri-green;
		color: $white;
	}

	.backArrow, .forwardArrow {
		background-color: $pri-green;
		color: $white;
		.icon {
			font-size: 1.2em;
		}
	}

	.backArrow {
		transform: rotate(180deg);
	}
}

.pagination {
	content: '';
	clear: both;
	display: table;
}
