// calendar styles

$catRed: #B22A2E;
$catYellow: #FECE00;
$catGreen : #1B5735;
$catOrange: #FF9E19;

#calendarPage {
  @include desktop {
    // background-color: #fff;
  }
}

.events-links {
  .icon-calendar {
    padding: 5px 0 0 6px;
  }
}

#filters {
  padding-bottom: 15px;
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;


  .category {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    outline: none;
    &.campsandorganisedactivities {
      background: $catRed;
      color: #fff;
    }
    &.guidedwalks {
      background: $catYellow;
      color: #000;
    }
    &.ubinvolunteers {
      background: $catOrange;
      color: #000;
    }
    &.nature {
      background: $catGreen;
      color: #fff;
    }
    &.active {
      &.ubinvolunteers {
        background: $catOrange;
      }
    }
  }

  p {
    text-align: center;
    padding-top: 20px;
    font-size: 10.5pt;
    @include desktop {
      display: inline-block;
      padding-top: 15px;
    }
  }

  .wrap {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    z-index: 2;
    @include desktop {
      display: inline-block;
    }
  }

  button {
    margin-bottom: 8px;
    cursor: pointer;

    @include desktop {
      margin-right: 10px;
    }
    &:after {
      content: '+';
      padding-left: 10px;
    }
    &.active {
      &:after {
        content: '✓';
      }
    }
  }
}

.fc-event-container {
  a {
    display: none;
  }
  &:after {
    @include desktop {
      content: none;
    }
    @include custom-mobile {
      content: '•';
      position: absolute;
      color: #CCCCCC;
      font-size: 26pt;
      top: 10px;
      /* left: 10px; */
      margin-left: 15px;
    }
  }
}

.fc-view-container {
  @include custom-mobile {
    overflow: visible;

    .fc-scroller {
      overflow-y: hidden !important;
    }
  }
}

.wrapCalendar {
  @include custom-mobile {
    background: #fff;
  }
  @include desktop {
    padding-bottom: 70px;
  }
  #calendar {
    padding-top: 20px;
    @include custom-mobile {
      padding: 30px;
    }
  }
}

.fc-today-button {
  display: none;
}

.fc-header-toolbar {
  // position: absolute;
  // top: 89px;
  // left: 50%;
  // transform: translateX(-50%);
  // .fc-left {
  //   h2 {
  //     display: none;
  //   }
  // }
  // .fc-button-group {
  //   button {
  //     height: 40px;
  //     &:first-child {
  //       position: relative;
  //       right: 150px;
  //     }
  //     &:last-child {
  //       position: relative;
  //       left: 150px;
  //     }
  //   }
  // }
  display: none;
}

.wrapSelect {
  &-mobile {
    background-color: #fff;
    padding-top: 25px;
    @include desktop {
      display: none;
    }
    @include custom-mobile {
      display: block;
      text-align: center;
    }
    button {
      height: 40px;
      width: 40px;
      position: relative;
      bottom: 4px;
      background: #fff;
      border: 1px solid buttonface;
      text-indent: -99999px;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
        display: block;
        position: relative;
      }
      &.prevBtn {
        top: 11px;
        right: 20px;
      }
      &.nextBtn {
        bottom: 11px;
        left: 20px;
      }
    }
  }
  &-desktop {
    text-align: center;
    @include desktop {
      display: block;
      padding-top: 30px;
      padding-bottom: 20px;
    }
    @include custom-mobile {
      display: none;
    }
    button {
      height: 40px;
      width: 40px;
      position: relative;
      bottom: 4px;
      background: #fff;
      border: 1px solid buttonface;
      text-indent: -99999px;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
        display: block;
        position: relative;
      }
      &.prevBtn {
        top: 7px;
      }
      &.nextBtn {
        bottom: 14px;
      }
    }
  }
}

.prevBtn {
  img {
    transform: rotate(-90deg);
    left: 2px;
  }
}

.nextBtn {
  img {
    transform: rotate(90deg);
    top: -10px;
    left: 3px;
  }
}

.selectMonth {
  border: none;
  font-size: 1.5em;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align-last: center;
  background: transparent;
  font-size: 13pt;
  font-weight: bolder;
  outline: none;

  @include desktop {
    font-size: 20pt;
    background-color: white;
    padding: 5px;
    height: 40px;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
  }
  @include custom-mobile {
    display: inline-block;
  }
}

.fc-sat, .fc-sun {
  @include desktop {
    background-color: #F0F5F1;
  }
}

.fc {
  padding-left: 30px;
  padding-right: 30px;

  @include desktop {
    padding-left: 0;
    padding-right: 0;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
  }

  &-header-toolbar {
    position: absolute;

    @include custom-mobile {
      top: 200px;
    }
  }
}



.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  @include desktop {
    border-color: #ddd;
  }
  @include custom-mobile {
    border-color: #fff;
  }
}

.fc-unthemed .fc-disabled-day {
  @include custom-mobile {
    background-color: #fff;
  }
}

.fc-day-header {
  @include desktop {
    background-color: transparent;
    border-color: #fff;;
  }
}

.fc-unthemed td.fc-today {
  @include custom-mobile {
    background: #000;
    color: #fff;
    border: none;
  }
  background: none;
}

.fc-day {
  @include desktop {
    padding-top: 40px !important;
  }
  @include tablet {
    padding-top: 25px !important;
  }
  @include custom-mobile {
    border-radius: 8px;
  }
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: none;
  display: block;
  text-align: center;
  @include desktop {
    text-align: right;
    padding-right: 25px;
    padding-top: 15px;
  }
}

.fc-row {
  .fc-content-skeleton {
    @include custom-mobile {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}


#mobileEventDetails {
  @include custom-mobile {
    display: block;
    min-height: 200px;
  }
  display: none;
  .mobileEventList {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    background: #fff;

    .event {
      padding: 25px 0;
      display: block;

      &.hide {
        display: none;
      }

      .moreinfo {
        font-weight: bolder;
        padding-top: 10px;
        display: block;
      }

      .eventName {
        margin-bottom: 5px;
        margin-top: 23px;
      }

      .location {
        display: block;
        padding-bottom: 5px;
      }
      .timeHolder {
        display: block;
      }
    }
  }
  #display {
    padding-top: 20px;
    padding-left: 30px;
    padding: 20px 30px;
    font-weight: bold;
  }
}

.catLabel {
  color: #fff;
  padding: 4px 6px;
  font-size: 9pt;
  @include custom-mobile {
    display: none;
  }
  &.guidedwalks {
    background-color: $catYellow;
    color: #000;
  }
  &.nature {
    background-color: $catGreen;
  }
  &.ubinvolunteers {
    background-color: $catOrange;
    // border: 1px solid $catOrange;
    color: #000;
  }
  &.campsandorganisedactivities {
    background-color: $catRed;
  }
}

.fc-content {
  &:hover {
    .fc-title {
      color: #000;
    }
  }
}

.eventParent {
  background-color: #fff;
  font-size: 10.5pt;
  cursor: pointer;
  margin: 0 5px 5px;
  height: 22px;
  overflow: auto;
  &.hide {
    display: none;
  }
  @include custom-mobile {
    display: none;
  }
  .event-count {
    color: #000;
    font-size: 9pt;
    padding-left: 10px;
  }
}

.tippy-tooltip.default-theme {
  background-color: #fff;
  border-radius: 5px;
  color: #000;
}

.fc-week {
  min-height: 160px !important;

  @include custom-mobile {
    min-height: 4em !important;
  }

  @include mobile {
    min-height: 4em !important;
  }
}

.tippy-popper[x-placement^=top] .tippy-arrow {
  border-top: 7px solid #fff;
}

.tippy-popper[x-placement^=bottom] .tippy-arrow {
  border-bottom: 7px solid #fff;
}

.tippy-popper[x-placement^=top] [data-animation=shift-away][data-state=visible] {
  transform: translateY(-5px) !important;
}

.tippy-popper[x-placement^=bottom] [data-animation=shift-away][data-state=visible] {
  transform: translateY(5px) !important;
}

.tooltipWrap {
  text-align: left;
  .date {
    display: block;
  }

  .category {
    display: inline-block;
    margin-top: 10px;
  }

  .eachEvent {
    h3 {
      text-align: left;
    }
    span {
      display: block;
      font-size: 10pt;
    }
    a {
      color: $catGreen;
      font-weight: bolder;
    }
  }
}

.eventHover {
  display: none;
}
