/*
* Breakpoints
*/
$bpMobile: 320px;
$bpLgMobile: 480px;
$bpTablet: 768px;
$bpDesktop: 961px;
$bpLgDesktop: 1367px;

/*
* Fonts
*/

$base-fontsize: 16px;
$primaryfont: 'Open Sans', Arial, Helvetica, sans-serif;
$secondaryfont: 'Open Sans', Arial, Helvetica, sans-serif;

/*
* Color
*/

$body-text: #656565;
$clear-white: rgba(255, 255, 255, 0);
$clear-black: rgba(0, 0, 0, 0);
$black-80: rgba(0, 0, 0, .8);
$black-70: rgba(0, 0, 0, .7);
$white-70: rgba(220, 220, 220, .92);
$white-box: rgba(200, 200, 200, .92);
$black: #000;
$white: #fff;
$weather-off-white: #f1f0ed;
$text-hover: rgba(150, 150, 150, 1);
$text-off-white: #c8c8c8;
$search-bar-grey: #eee;
$grey: rgba(145, 145, 145, 1);
$pri-green: #006224;  // primary green color
// $nav-hover: rgba(0, 133, 49, 0.7);
$nav-hover: #1fb355;
$grey-overlay: rgba(150, 150, 150, .85);
$lightgrey: #d3d3d3;
$icon-brown: #6f5831;
$weather-text: rgba(90, 90, 90, 1);
$mobile-icon: #6e6e6e;
$notice-email-color: #db9130;
$standard-bg: #f7f7f7;
$modal-bg: rgba(0, 0, 0, .4);
$footer-green: #006224;
$mapFilters-bg: rgba(255, 255, 255, .77);
$navbarBlack: #202020;
// another green #1D5632
// $headerGradTop: rgba(25, 25, 25, 0.95);
// $headerGradMid1: rgba(30, 30, 30, 0.65);
// $headerGradMid2: rgba(30, 30, 30, 0.23);
// $headerGradBot: rgba(90, 90, 90, 0.01);

$headerGradTop: rgba(250, 250, 250, .95);
$headerGradMid1: rgba(250, 250, 250, .65);
$headerGradMid2: rgba(250, 250, 250, .23);
$headerGradBot: rgba(220, 220, 220, .01);
$map-orange: #f2951c;
$map-blue: #09c3cf;
$map-trails-green: #149d06;
$map-trails-orange: #d54a08;
$map-trails-blue: #1c3894;

//social colors:
$facebook: #3b5998;
$twitter: #00aced;
$mail: #4999dc;
