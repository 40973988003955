@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?60orj0');
  src:  url('../fonts/icomoon.eot?60orj0#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?60orj0') format('truetype'),
    url('../fonts/icomoon.woff?60orj0') format('woff'),
    url('../fonts/icomoon.svg?60orj0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    vertical-align: initial;
    text-transform: none;

    speak: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        font-family: 'icomoon' !important;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        line-height: 1;

        vertical-align: initial;
        text-transform: none;

        speak: none;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

%icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    vertical-align: initial;
    text-transform: none;

    speak: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\e92b";
}
.icon-envelop:before {
  content: "\e928";
}
.icon-twitter:before {
  content: "\e927";
}
.icon-brand:before {
  content: "\e927";
}
.icon-tweet:before {
  content: "\e927";
}
.icon-social:before {
  content: "\e927";
}
.icon-facebook:before {
  content: "\e926";
}
.icon-brand2:before {
  content: "\e926";
}
.icon-social2:before {
  content: "\e926";
}
.icon-search:before {
  content: "\e901";
}
.icon-zoom:before {
  content: "\e901";
}
.icon-magnifier:before {
  content: "\e901";
}
.icon-magnifying-glass:before {
  content: "\e901";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-clock:before {
  content: "\e913";
}
.icon-download:before {
  content: "\e914";
}
.icon-Event-location-pin:before {
  content: "\e915";
}
.icon-food .path1:before {
  content: "\e916";
  color: rgb(9, 195, 207);
}
.icon-food .path2:before {
  content: "\e917";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-food .path3:before {
  content: "\e918";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-location-pin .path1:before {
  content: "\e919";
  color: rgb(242, 149, 28);
}
.icon-location-pin .path2:before {
  content: "\e91a";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-location-pin-selected .path1:before {
  content: "\e91b";
  color: rgb(234, 92, 6);
}
.icon-location-pin-selected .path2:before {
  content: "\e91c";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-star:before {
  content: "\e91d";
}
.icon-ticket:before {
  content: "\e91e";
}
.icon-toilet .path1:before {
  content: "\e91f";
  color: rgb(9, 195, 207);
}
.icon-toilet .path2:before {
  content: "\e920";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-toilet .path3:before {
  content: "\e921";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-toilet .path4:before {
  content: "\e922";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-toilet .path5:before {
  content: "\e923";
  margin-left: -1.052734375em;
  color: rgb(255, 255, 255);
}
.icon-end-arrow:before {
  content: "\e911";
}
.icon-Next-arrow:before {
  content: "\e912";
}
.icon-Ubin-Project:before {
  content: "\e90f";
}
.icon-About-info:before {
  content: "\e902";
}
.icon-Activities:before {
  content: "\e903";
}
.icon-Biodiversity:before {
  content: "\e904";
}
.icon-Facebook:before {
  content: "\e905";
}
.icon-Heritage:before {
  content: "\e906";
}
.icon-Home:before {
  content: "\e907";
}
.icon-Instagram:before {
  content: "\e908";
}
.icon-Twitter:before {
  content: "\e90a";
}
.icon-Volunteers:before {
  content: "\e90b";
}
.icon-arrow_drop_up:before {
  content: "\e909";
}
.icon-arrow_drop_down:before {
  content: "\e910";
}
.icon-chevron-left:before {
  content: "\e90d";
}
.icon-chevron-right:before {
  content: "\e90e";
}
.icon-chevron-thin-down:before {
  content: "\e925";
}
.icon-chevron-thin-up:before {
  content: "\e924";
}
.icon-angle-left:before {
  content: "\e929";
}
.icon-angle-right:before {
  content: "\e92a";
}
.icon-close:before {
  content: "\e90c";
}
.icon-play-circle:before {
  content: "\e900";
}
